import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import axios from 'axios';
import ReactLoading from 'react-loading';
import { Button, ButtonGroup, Alert } from '@dataesr/react-dsfr';

import AuthService from './services/auth.service';
import eventBus from './eventBus';
import { aTraiterRoutesBaseName } from './configs/routes';

function Login({ loaderColor }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [logingFailed, setLogingFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (location.search && location.search.includes('?_et')) {
      setLogingFailed(false);
      setErrorMessage('');
      const id = location.search.slice(5);
      AuthService.setId(id);
      axios.get(`/oauth2/token/${id}`).then((response) => {
        // Ensure token is set before fetching
        AuthService.setToken(response.data.data._token).then((_) => {
          navigate(aTraiterRoutesBaseName, { replace: true });
          eventBus.dispatch('connectionSuccess', {
            message: 'Connexion réussie',
          });
        });
      });
    }
  }, [location, navigate]);

  if (!localStorage.getItem('_token')) {
    if (
      !(
        location.search &&
        (location.search.includes('?_et') ||
          location.search.includes('?_error'))
      )
    ) {
      AuthService.loginWithCerbere();
    }
  }

  useEffect(() => {
    if (location.search && location.search.includes('?_error')) {
      setLogingFailed(true);
      const message = location.search.slice(8);
      setErrorMessage(decodeURI(message));
    }
  });

  const retryLogin = () => {
    AuthService.loginWithCerbere();
  };

  return (
    <>
      {logingFailed ? (
        <>
          {/* TODO: Styling */}
          <Alert
            title="Connexion impossible"
            description={errorMessage}
            type="error"
          />
          <ButtonGroup isEquisized align="center" isInlineFrom="md">
            <Button title="Réessayer" onClick={() => retryLogin(true)}>
              Réessayer
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <div className="fr-app-loading-container">
          <ReactLoading
            type="spin"
            color={loaderColor}
            height={100}
            width={100}
          />
          <p>Connexion en cours</p>
        </div>
      )}
    </>
  );
}

Login.propTypes = {
  loaderColor: PropTypes.string.isRequired,
};

export default Login;
