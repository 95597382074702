import { allCursusEnum } from './CursusEnum';

// Correspondance code type gestion Front/Back
export const TypeDeGestionCursusEnum = {
  COMPLETE: 1,
  SIMPLIFIEE: 2,
  A_DISTANCE: 3,
};

export const CursusEnum = allCursusEnum;

export const ModulesEnum = {
  FOAD: 13,
  SEMAINE_METIER: 15,
  SEMAINE_TECHNIQUE: 1,
  SEMAINE_APPROFONDISSEMENT: 16,
  TUTORAT: 7,
};

export const EtatFormationEnum = {
  REFUSEE: 'REFUSEE',
  ANNULEE: 'ANNULEE',
  A_TRANSFERER: 'A_TRANSFERER',
  TRANSFEREE: 'TRANSFEREE',
  VALIDEE: 'VALIDEE',
  ACCEPTEE: 'ACCEPTEE',
  EN_ATTENTE: 'EN_ATTENTE',
  A_INSCRIRE: 'A_INSCRIRE',
  INSCRIT: 'INSCRIT',
};

export const EtatSessionEnum = {
  CLOTUREE: 'CLOTUREE',
  OUVERTE: 'OUVERTE',
  ANNULEE: 'ANNULEE',
  REALISEE: 'REALISEE',
};

export const instanceTypeFormationEnum = {
  FOAD: 'FOAD',
  TUTORAT: 'TUTORAT',
  EQUIVALENCE: 'EQUIVALENCE',
  INSCRIPTION: 'INSCRIPTION',
};

export const inscriptionModuleParCursusAction = {
  WORKFLOW_INSCRIPTION_SEMAINES_M_A_T:
    'inscriptionsSemainesMetierApprofondissementEtTechnique',
  TUTORAT: 'gestionTutorat',
  INSCRIPTION_FOAD: 'inscriptionFoad',
  VALIDATION_FOAD: 'validationFoad',
  INSCRIPTION_FORMATION: 'inscriptionFormation',
  MODIFIER_SESSION: 'modifierSession',
  BASCULER_EQUIVALENCE: 'basculerEnEquivalence',
  BASCULER_SESSION: 'basculerEnSession',
  TRANSFERT_SESSION: 'transfererSession',
  TRANSFERT_EN_COURS: 'transfertEnCours',
};

export const commissionnementArretesAction = {
  ARRETES_A_EDITER: 'editer',
  ARRETES_EDITES_AVEC_NOR: 'editesavecnor',
  ARRETES_NOTIFIES: 'notifies',
};
