import axios from 'axios';
import React, { useState, useEffect } from 'react';
import TechnicalError from '../../components/shared/technicalError/TechnicalError';
import { stats } from '../../configs/routesAPI';
import { saveAs } from 'file-saver';
import { ButtonGroup, Col, Container, Row } from '@dataesr/react-dsfr';
import { Link } from 'react-router-dom';
import SelectAnnee from '../../components/shared/selects/SelectAnnee';
import { useForm } from 'react-hook-form';
import { localStorageSearchKey } from '../../configs/localStorageSearchKey';

export default function PagesStatsAgentsSuiviRepartitionPDPListe() {
  const [apiError, setApiError] = useState(null);
  const currentYear = new Date().getFullYear();

  const { register, getValues, watch, setValue } = useForm({
    defaultValues: {
      annee: currentYear - 1,
    },
  });
  watch({ annee: currentYear - 1 });

  const params = {
    annee: null,
  };

  useEffect(() => {
    // Initialisation des valeurs du formulaires
    let inputParam = localStorage.getItem(
      localStorageSearchKey.searchStatistiquesListe
    );
    if (inputParam !== null) {
      inputParam = JSON.parse(inputParam);
      setValue('annee', inputParam.annee);
    }
    setTitle(getValues('annee'));
  }, [watch]);

  const handleChange = (e) => {
    setTitle(e.target.value);
    params.annee = e.target.value;
    setValue(e.target.name, params.annee, true);
    localStorage.setItem(
      localStorageSearchKey.searchStatistiquesListe,
      JSON.stringify(params)
    );
  };

  const setTitle = (value) => {
    document.title =
      'Suivi mensuel des agents qui ont suivi le cursus Prise de poste ' +
      value +
      ' | Commissionnement';
  };

  const downloadCsv = (event) => {
    event.preventDefault();
    params.annee = getValues('annee');

    axios({
      url: stats.exportAgentsSuiviRepartitionPDP,
      params: params,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });
        saveAs(
          blob,
          'Tableau_bord_suivi_répartition_prise_de_poste_' +
            getValues('annee') +
            '.xlsx'
        );
      })
      .catch((error) => {
        setApiError(error);
      });
  };

  return (
    <>
      <div>
        <TechnicalError error={apiError} />

        <section className="fr-app-section">
          <form className="fr-app-form">
            <Container fluid>
              <Row className={'align-center'}>
                <Col n="3">
                  <SelectAnnee
                    register={register}
                    handleChange={handleChange}
                    anneeMin={2010}
                    anneeMax={currentYear}
                  ></SelectAnnee>
                </Col>
              </Row>
            </Container>
          </form>
          <div className="fr-table fr-app-table no-padding-top">
            <div className="fr-app-table">
              <caption style={{ display: 'block' }}>
                Suivi mensuel des agents qui ont suivi le cursus Prise de poste
                en {getValues('annee')}
              </caption>
            </div>
          </div>

          <ButtonGroup isEquisized align="center" isInlineFrom="md">
            <Link
              type="button"
              className="fr-btn--md fr-btn btn-action-trigger"
              title="Export CSV"
              onClick={downloadCsv}
              to={''}
              download
            >
              Export CSV
            </Link>
          </ButtonGroup>
        </section>
      </div>
    </>
  );
}
