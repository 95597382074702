import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TableWithLoader from '../../../components/shared/tables/TableWithLoader';
import { Link } from 'react-router-dom';

export default function ConsulterCommissionnementsCartesEnAttenteEditionTable({
  agents,
}) {
  const [agentsAvailable, setAgentsAvailable] = useState([]);

  useEffect(() => {
    setAgentsAvailable(agents);
  }, [agents]);

  const columns = [
    {
      name: 'nom_affichage',
      label: 'Nom',
      render: ({ nom_affichage, decommissionnement_en_cours }) => {
        return (
          <>
            {decommissionnement_en_cours ? (
              <div>
                <Link
                  title="Décommissionnement en cours de l'agent"
                  aria-label="Décommissionnement en cours de l'agent"
                  className="btn-action-trigger"
                  to="#"
                >
                  <span
                    aria-hidden="true"
                    className="fr-app-icon fr-app-icon-warning"
                    style={{
                      position: 'absolute',
                      marginLeft: '-0.5em',
                      marginTop: '-1.5em',
                    }}
                  />
                </Link>
                <span>{nom_affichage}</span>
              </div>
            ) : (
              <>
                <span>{nom_affichage}</span>
              </>
            )}
          </>
        );
      },
    },
    {
      name: 'prenom',
      label: 'Prénom',
    },
    {
      name: 'type_commissionnement_code',
      label: 'Type Com.',
    },
    {
      name: 'habilitation_label',
      label: 'Commissionnement',
    },
    {
      name: 'zone_commissionnement_label',
      label: 'Zone géographique',
    },
    {
      name: 'motif',
      label: 'Motif',
    },
  ];

  return agentsAvailable ? (
    <form className="fr-app-form">
      <TableWithLoader
        data={agentsAvailable}
        columns={columns}
        isLoading={false}
        rowKey="agent_id"
        customClassName="commissionnements-carte-en-attente-edition-tab no-caption "
      ></TableWithLoader>
    </form>
  ) : (
    <></>
  );
}

ConsulterCommissionnementsCartesEnAttenteEditionTable.propTypes = {
  agents: PropTypes.array,
  typeCommissionnement: PropTypes.any,
};
