import React from 'react';
import PropTypes from 'prop-types';

import TableWithLoader from '../../../components/shared/tables/TableWithLoader';

export default function ConsulterChangementDeZoneEnAttenteTable({
  agents,
  isLoading,
}) {
  const columns = [
    {
      name: 'nom_affichage',
      label: 'Nom',
    },
    {
      name: 'prenom',
      label: 'Prénom',
    },
    {
      name: 'type_commissionnement_label',
      label: 'Type Com.',
    },
    {
      name: 'zone_actuelle_label',
      label: 'Zone actuelle',
    },
    {
      name: 'zone_demandee_label',
      label: 'Zone demandée',
    },
  ];

  return (
    <>
      <div className="fr-app-table">
        <caption style={{ display: 'block' }}>
          Liste des propositions de changement de zone de commissionnement
        </caption>
      </div>
      <TableWithLoader
        data={agents}
        columns={columns}
        isLoading={isLoading}
        rowKey="agent_id"
        customClassName="agents-changement-de-zone-tab no-caption"
      ></TableWithLoader>
    </>
  );
}

ConsulterChangementDeZoneEnAttenteTable.propTypes = {
  agents: PropTypes.array,
  isLoading: PropTypes.bool,
};
