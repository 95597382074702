import PropTypes from 'prop-types';
import React from 'react';

export default function UserProfil({ profil }) {
  return (
    <ul>
      <li>Application : {profil.application_context_label}</li>
      <li>Role : {profil.role_label}</li>
      {profil.types_commissionnement.length ? (
        <li>
          Types de commissionnement : {profil.types_commissionnement.join(', ')}
        </li>
      ) : (
        ''
      )}
      {profil.organisme_gestionnaire_id ? (
        <li>Organisme gestionnaire : {profil.organisme_gestionnaire}</li>
      ) : (
        ''
      )}
      {profil.sous_organisme ? (
        <li>Sous-organisme : {profil.sous_organisme}</li>
      ) : (
        ''
      )}
    </ul>
  );
}

UserProfil.propTypes = {
  profil: PropTypes.object,
};
