import React from 'react';
import PropTypes from 'prop-types';

import TableWithLoader from '../../../components/shared/tables/TableWithLoader';
import DateHelper from '../../../helpers/DateHelper';

export default function InscriptionsEnAttenteTable({ agents, isLoading }) {
  const columns = [
    {
      name: 'datedemande',
      label: 'Date demande',
      render: ({ date_demande }) => DateHelper.format(date_demande),
    },
    {
      name: 'session',
      label: 'Session',
      render: ({ numero }) => numero,
    },
    {
      name: 'nom',
      label: 'Nom',
      render: ({ nom, nom_usage }) => nom_usage ?? nom,
    },
    {
      name: 'prenom',
      label: 'Prénom',
    },
    {
      name: 'service',
      label: 'Service',
    },
    {
      name: 'module',
      label: 'Module',
      render: ({ module_label }) => module_label,
    },
    {
      name: 'datedebut',
      label: 'Date début',
      render: ({ date_debut }) => DateHelper.format(date_debut),
    },
    {
      name: 'datedefin',
      label: 'Date de fin',
      render: ({ date_fin }) => DateHelper.format(date_fin),
    },
    {
      name: 'prisedeposte',
      label: 'Prise de poste',
      render: ({ date_prise_poste }) => DateHelper.format(date_prise_poste),
    },
  ];

  return (
    <>
      <div className="fr-app-table">
        <caption style={{ display: 'block' }}>
          Liste des demandes d’inscriptions
        </caption>
      </div>
      <TableWithLoader
        data={agents}
        columns={columns}
        isLoading={isLoading}
        rowKey="numero"
        customClassName="agents-demandes-inscriptions-tab no-caption"
      ></TableWithLoader>
    </>
  );
}

InscriptionsEnAttenteTable.propTypes = {
  agents: PropTypes.array,
  isLoading: PropTypes.bool,
};
