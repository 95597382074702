import React from 'react';
import { Routes } from 'react-router-dom';
import { Route } from 'use-react-router-breadcrumbs';
import { enCoursDeTraitementRoutesRelative } from '../../configs/routes';
import PagesEnCoursDeTraitement from '../../pages/encoursdetraitement/PagesEnCoursDeTraitement';
import UserService, { EAU_ET_NATURE, ICPE } from '../../services/user.service';
import DemandesCreationAgentEnAttente from '../../pages/encoursdetraitement/DemandesCreationAgentEnAttente';
import PagesInscriptionsEnAttente from '../../pages/encoursdetraitement/consulterInscriptionsEnAttente/PagesInscriptionsEnAttente';
import PagesConsulterDemandesTransfert from '../../pages/encoursdetraitement/consulterDemandesTransfert/PagesConsulterDemandesTransfert';
// eslint-disable-next-line max-len
import PagesConsulterPropositionsCommissionnement from '../../pages/encoursdetraitement/consulterPropositionsCommissionnement/PagesConsulterPropositionsCommissionnement';
// eslint-disable-next-line max-len
import PagesConsulterCommissionnementsCartesEnAttenteEdition from '../../pages/encoursdetraitement/consulterCommissionnementsCartesEnAttenteEdition/PagesConsulterCommissionnementsCartesEnAttenteEdition';
// eslint-disable-next-line max-len
import PagesConsulterChangementDeZoneEnAttente from '../../pages/encoursdetraitement/consulterChangementDeZoneEnAttente/PagesConsulterChangementDeZoneEnAttente';
// eslint-disable-next-line max-len
import PagesConsulterPrestationsDeSermentEnAttente from '../../pages/encoursdetraitement/consulterPrestationsDeSermentEnAttente/PagesConsulterPrestationsDeSermentEnAttente';
// eslint-disable-next-line max-len
import PagesConsulterChangementsCommissionnementEnAttente from '../../pages/encoursdetraitement/consulterChangementsCommissionnementEnAttente/PagesConsulterChangementsCommissionnementEnAttente';
// eslint-disable-next-line max-len
import PagesConsulterCommissionnementsEnAttenteArrete from '../../pages/encoursdetraitement/consulterCommissionnementsEnAttenteArrete/PagesConsulterCommissionnementsEnAttenteArrete';
// eslint-disable-next-line max-len
import PagesDecommissionnementEnAttenteArrete from '../../pages/encoursdetraitement/consulterDecommissionnementEnAttenteArrete/PagesDecommissionnementEnAttenteArrete';

export default function EnCoursDeTraitementRoutes() {
  const isIcpe = ICPE === UserService.applicationContext();
  const isEauNature = EAU_ET_NATURE === UserService.applicationContext();

  return (
    <div className="content">
      <Routes>
        <Route
          path={enCoursDeTraitementRoutesRelative.base}
          element={<PagesEnCoursDeTraitement />}
        />
        {isIcpe ? (
          <>
            <Route
              path={enCoursDeTraitementRoutesRelative.agentsCreationsEnAttente}
              element={<DemandesCreationAgentEnAttente />}
            />
            <Route
              path={enCoursDeTraitementRoutesRelative.inscriptionsEnAttente}
              element={<PagesInscriptionsEnAttente />}
            />
            <Route
              path={enCoursDeTraitementRoutesRelative.demandesDeTransfert}
              element={<PagesConsulterDemandesTransfert />}
            />
          </>
        ) : (
          ''
        )}
        <Route
          path={enCoursDeTraitementRoutesRelative.commissionnementsPropositions}
          element={<PagesConsulterPropositionsCommissionnement />}
        />
        <Route
          path={
            enCoursDeTraitementRoutesRelative.commissionnementsEnAttenteArrete
          }
          element={<PagesConsulterCommissionnementsEnAttenteArrete />}
        />
        <Route
          path={
            enCoursDeTraitementRoutesRelative.commissionnementsCartesAEditer
          }
          element={<PagesConsulterCommissionnementsCartesEnAttenteEdition />}
        />
        <Route
          path={enCoursDeTraitementRoutesRelative.changementsZoneEnAttente}
          element={<PagesConsulterChangementDeZoneEnAttente />}
        />
        {isEauNature ? (
          <Route
            path={
              enCoursDeTraitementRoutesRelative.changementsCommissionnementEnAttente
            }
            element={<PagesConsulterChangementsCommissionnementEnAttente />}
          />
        ) : (
          ''
        )}
        <Route
          path={enCoursDeTraitementRoutesRelative.prestationsEnAttente}
          element={<PagesConsulterPrestationsDeSermentEnAttente />}
        />
        <Route
          path={
            enCoursDeTraitementRoutesRelative.decommissionnementsEnAttenteArrete
          }
          element={<PagesDecommissionnementEnAttenteArrete />}
        />
      </Routes>
    </div>
  );
}
