import React from 'react';
import PropTypes from 'prop-types';

import TableWithLoader from '../../../components/shared/tables/TableWithLoader';
import DateHelper from '../../../helpers/DateHelper';

export default function ConsulterDemandesTransfertTable({ agents, isLoading }) {
  const columns = [
    {
      name: 'datedemande',
      label: 'Date demande',
      render: ({ date_demande }) => DateHelper.format(date_demande),
    },
    {
      name: 'nom',
      label: 'Nom',
      render: ({ nom, nom_usage }) => nom_usage ?? nom,
    },
    {
      name: 'prenom',
      label: 'Prénom',
    },
    {
      name: 'service',
      label: 'Service',
    },
    {
      name: 'module',
      label: 'Module',
      render: ({ module_label }) => module_label,
    },
    {
      name: 'motiftransfert',
      label: 'Motif du transfert',
      render: ({ motif_transfert }) => motif_transfert,
    },
  ];

  return (
    <>
      <div className="fr-app-table">
        <caption style={{ display: 'block' }}>
          Liste des demandes de transfert
        </caption>
      </div>
      <TableWithLoader
        data={agents}
        columns={columns}
        isLoading={isLoading}
        rowKey="id"
        customClassName="agents-demandes-transferts-tab no-caption"
      ></TableWithLoader>
    </>
  );
}

ConsulterDemandesTransfertTable.propTypes = {
  agents: PropTypes.array,
  isLoading: PropTypes.bool,
};
