import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';

import colors from './styles/color.scss';
import eventBus from './eventBus';
import Login from './Login';
import UserLoggedIn from './UserLoggedIn';
import UserService from './services/user.service';

function Layout() {
  const [hasToken, setHasToken] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const eaColor = colors.apporange;
  const icpeColor = colors.appgreen;
  const loaderColor =
    'EAU_ET_NATURE' === UserService.applicationContext() ? eaColor : icpeColor;

  eventBus.on('connectionSuccess', (_) => {
    setHasToken(true);
  });

  eventBus.on('loggingOut', (_) => {
    setIsLoggingOut(true);
  });

  eventBus.on('loggingOutFailed', (_) => {
    setIsLoggingOut(false);
  });

  useEffect(() => {
    if (localStorage.getItem('_token')) {
      setHasToken(true);
    }
  }, []);

  if (hasToken) {
    if (isLoggingOut) {
      return (
        <div className="fr-app-loading-container">
          <ReactLoading
            type="spin"
            color={loaderColor}
            height={100}
            width={100}
          />
          <p>Déconnexion en cours</p>
        </div>
      );
    } else {
      return <UserLoggedIn loaderColor={loaderColor} />;
    }
  } else {
    return <Login loaderColor={loaderColor} />;
  }
}

export default Layout;
