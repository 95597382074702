import React from 'react';
import PropTypes from 'prop-types';

import TableWithLoader from '../../../components/shared/tables/TableWithLoader';

export default function ConsulterDecommissionnementEnAttenteArreteTable({
  decommissionnements,
  isLoading,
}) {
  const columns = [
    {
      name: 'nom_affichage',
      label: 'Nom',
    },
    {
      name: 'prenom',
      label: 'Prénom',
    },
    {
      name: 'type_commissionnement_code',
      label: 'Type Com',
    },
    {
      name: 'habilitation_label',
      label: 'Commissionnement',
    },
    {
      name: 'zone_commissionnement_label',
      label: 'Zone géographique',
    },
  ];

  return (
    <>
      <div className="fr-app-table">
        <caption style={{ display: 'block' }}>
          Liste des décommissionnements en attente de l'arrêté ministériel
        </caption>
      </div>
      <TableWithLoader
        data={decommissionnements}
        columns={columns}
        isLoading={isLoading}
        rowKey="id"
        customClassName="no-caption"
      ></TableWithLoader>
    </>
  );
}

ConsulterDecommissionnementEnAttenteArreteTable.propTypes = {
  decommissionnements: PropTypes.array,
  isLoading: PropTypes.bool,
};
