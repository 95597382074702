import React from 'react';
import CaseTransformerHelper from './CaseTransformerHelper';

class FormHelper {
  displayErr(champBack, champFront, setError) {
    if (champBack) {
      setError(champFront, {
        type: 'server',
        message: champBack[0],
      });
    }
  }

  displayErrHtml(champFront) {
    let msg = '';
    if (champFront) {
      switch (champFront.type) {
        case 'required':
          msg = 'Ce champ est obligatoire';
          break;
        case 'min':
          msg = champFront.message
            ? 'La valeur ne peut être inférieure à ' + champFront.message
            : 'La valeur est trop petite.';
          break;
        case 'max':
          msg = champFront.message
            ? 'La valeur ne peut être supérieur à ' + champFront.message
            : 'La valeur est trop grande.';
          break;
        case 'maxLength':
          msg = champFront.message
            ? 'La longueur maximale est de ' + champFront.message
            : 'La longueur du champ est trop longue.';
          break;
        case 'minLength':
          msg = champFront.message
            ? 'La longueur minimale est de ' + champFront.message
            : 'La longueur du champ est trop courte.';
          break;
        case 'pattern':
          msg = champFront.message
            ? 'Le champ ne respect pas le format: ' + champFront.message
            : 'Format incorrect.';
          break;
        default:
          msg = champFront.message
            ? champFront.message
            : 'Une erreur sur le champ a été rencontrée.';
          break;
      }
    }
    return champFront ? <span className="fr-error-text">{msg}</span> : <></>;
  }

  handleApiFormError(error, setError) {
    if (error.response && 422 === error.response.status) {
      const apiErrors = error.response.data.errors;
      if (apiErrors.label) {
        setError('label', {
          type: 'server',
          message: apiErrors.label[0],
        });
      }

      return;
    }
    console.log({ error }); // TODO handle error
  }

  /**
   * Cette méthode permet de transformer uniquement les clés en CamelCase et ne
   * transforme pas la valeur en string si null (vs generateFormData)
   *
   * @param obj
   * @returns {*}
   */
  transformObjectKeyToSnakeCaseWithoutChangingValue(obj) {
    if (typeof obj !== 'object') return obj;

    for (var oldName in obj) {
      // Camel to underscore
      var newName = CaseTransformerHelper.camelToSnakeCase(oldName);
      // Only process if names are different
      if (newName !== oldName) {
        obj[newName] = obj[oldName];
        delete obj[oldName];
      }
      // Recursion
      if (typeof obj[newName] === 'object') {
        obj[newName] = this.transformObjectKeyToSnakeCaseWithoutChangingValue(
          obj[newName]
        );
      }
    }
    return obj;
  }

  // KeysOfTypeFile is an array of form keys for file input.
  generateFormData(formValues, KeysOfTypeFile) {
    const formData = new FormData();

    Object.keys(formValues).map((key) => {
      let value;
      if (KeysOfTypeFile && KeysOfTypeFile.includes(key)) {
        // Case where the key is an input File Type.
        value = formValues[key][0];
      } else {
        value = formValues[key];
      }

      return formData.append(
        CaseTransformerHelper.camelToSnakeCase(key),
        value
      );
    });
    return formData;
  }

  // Function to use if some form keys need to be renamed before camelCase to snake_case transformation.
  generateFormDataWithPayloadTransformation(formValues, keysToRename) {
    const formData = new FormData();

    Object.keys(formValues).map((key) => {
      const newKey =
        keysToRename && keysToRename[key] ? keysToRename[key] : key;

      return formData.append(
        CaseTransformerHelper.camelToSnakeCase(newKey),
        formValues[key]
      );
    });

    return formData;
  }

  multipartFormDataHeader() {
    return {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    };
  }

  transformStringChoiceToBool(formValues, valuesToTransform) {
    Object.keys(formValues).forEach((key) => {
      if (valuesToTransform.includes(key)) {
        let boolValue;
        switch (formValues[key]) {
          case 'refus':
            boolValue = false;
            break;
          case 'premier':
            boolValue = true;
            break;
          default:
            throw Error('Choix impossible');
        }
        formValues[key] = boolValue;
      }
    });

    return formValues;
  }
}

export default new FormHelper();
