import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonGroup, Button } from '@dataesr/react-dsfr';
import { enCoursDeTraitement } from '../../../configs/routes';
import ConsulterCommissionnementsCartesEnAttenteEditionTable from './ConsulterCommissionnementsCartesEnAttenteEditionTable';
import axios from 'axios';
import LoaderCommissionnement from '../../../components/shared/loaders/LoaderCommissionnement';

export default function PagesConsulterCommissionnementsCartesEnAttenteEdition() {
  let navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title =
      'Liste des commissionnements en attente de carte | Commissionnement';
    axios
      .get('/cartes-commissionnement/a-editer')
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  const setData = (agents) => {
    setAgents(agents);
    setIsLoading(false);
  };

  return (
    <section className="fr-app-section">
      <div className="fr-app-table">
        <caption style={{ display: 'block' }}>
          Liste des commissionnements en attente de carte
        </caption>
      </div>
      {isLoading ? (
        <LoaderCommissionnement className={'loader-center'} />
      ) : (
        <ConsulterCommissionnementsCartesEnAttenteEditionTable
          agents={agents}
        ></ConsulterCommissionnementsCartesEnAttenteEditionTable>
      )}
      <div style={{ marginTop: '1.25rem' }}>
        <ButtonGroup isEquisized align="center" isInlineFrom="md">
          <Button onClick={() => navigate(enCoursDeTraitement.base)} secondary>
            Retour à la liste
          </Button>
        </ButtonGroup>
      </div>
    </section>
  );
}
