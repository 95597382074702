export const userRoutesAPI = {
  getOriginalProfil: '/user/original-profil',
  getProfilDataStepOne: '/user/switch-profil-data-step-one',
  getProfilDataStepTwo:
    '/user/switch-profil-data-step-two/:applicationContextId/:roleId',
  switchProfil: '/user/switch-profil',
  switchBackProfil: '/user/switch-back-profil',
};

export const configurationRoutesAPI = {
  postForLookUpTable: '/lookup-table?active=true',
  getAllLieuFormation: '/configuration/formation/lieu-formation',
  getAllOrganimeFormation: '/configuration/formation/organisme-formation',
  getAllCursus: '/configuration/formation/cursus',
  getAllModule: '/configuration/formation/module',

  getModule: '/configuration/formation/module/:moduleId',
  getCursus: '/configuration/formation/cursus/:cursusId',
  zoneCommissionnementEndpoint:
    '/configuration/commissionnement/zone-commissionnement',
  getZoneCommissionnement:
    '/configuration/commissionnement/zone-commissionnement/:zoneCommissionnementId',
  postUpdateZoneCommissionnement:
    '/configuration/commissionnement/zone-commissionnement/update/:zoneCommissionnementId',

  expediteurBordereauEndpoint:
    '/configuration/commissionnement/expediteur-bordereau',
  getExpediteurBordereau:
    '/configuration/commissionnement/expediteur-bordereau/:expediteurBordereauId',
  postUpdateExpediteurBordereau:
    '/configuration/commissionnement/expediteur-bordereau/update/:expediteurBordereauId',
  destinataireBordereauEndpoint:
    '/configuration/commissionnement/destinataire-bordereau',
  getDestinataireBordereau:
    '/configuration/commissionnement/destinataire-bordereau/:destinataireBordereauId',
  postCreateDestinataireBordereau:
    '/configuration/commissionnement/destinataire-bordereau',
  postUpdateDestinataireBordereau:
    '/configuration/commissionnement/destinataire-bordereau/update/:destinataireBordereauId',

  getAllDepartements: '/departement',
  getAllRegions: '/region',
  getAllOrganismesGestionnaires: '/organisme-gestionnaire',
  getConfigurationApplication: '/configuration/application',
  getAssistanceDescription: '/configuration/application/assistanceDescription',
  getMailsCc: '/configuration/mails-cc',
  getExportMailsCc: '/configuration/export-mails-cc',
  getAllNotificationsType: '/notifications-types',
  getTypesActionATraiterSuperieurDeuxMois:
    '/configuration/types-action-a-traiter-2-mois',

  getManuelUtilisateur: '/manuel-utilisateur/:role',
  updateManuelUtilisateur: '/manuel-utilisateur',

  getGroupesTypeAdministrationAdminDgalnHabilitation:
    '/configuration/groupe-type-administration-dgaln-habilitation',
  getGroupeTypeAdministrationAdminDgalnHabilitation:
    '/configuration/groupe-type-administration-dgaln-habilitation/:gtaId',
  postGroupeTypeAdministrationAdminDgalnHabilitation:
    '/configuration/groupe-type-administration-dgaln-habilitation',
  updateGroupeTypeAdministrationAdminDgalnHabilitation:
    '/configuration/groupe-type-administration-dgaln-habilitation/update/:gtaId',

  getGroupesTypeAdministrationAdminDgaln:
    '/configuration/groupe-type-administration-dgaln',
  getGroupeTypeAdministrationAdminDgaln:
    '/configuration/groupe-type-administration-dgaln/:gtaId',
  postGroupeTypeAdministrationAdminDgaln:
    '/configuration/groupe-type-administration-dgaln',
  updateGroupeTypeAdministrationAdminDgaln:
    '/configuration/groupe-type-administration-dgaln/update/:gtaId',

  getGroupesTypeAdministrationAdminDgpr:
    '/configuration/groupe-type-administration-dgpr',
  getGroupeTypeAdministrationAdminDgpr:
    '/configuration/groupe-type-administration-dgpr/:gtaId',
  postGroupeTypeAdministrationAdminDgpr:
    '/configuration/groupe-type-administration-dgpr',
  updateGroupeTypeAdministrationAdminDgpr:
    '/configuration/groupe-type-administration-dgpr/update/:gtaId',

  typesAdministration: '/configuration/administration-type',
};

export const atraiterRouteAPI = '/atraiter';

export const enCoursDeTraitementRouteAPI = '/en-cours-de-traitement';

export const formationsRoutesAPI = {
  getAllSession: '/formations/session',
  postSaveSession: '/formations/session',
  getSession: '/formations/session/:sessionId',
  postUpdateSession: '/formations/session/update/:sessionId',
  deleteSession: '/formations/session/:sessionId',

  getEtatFormation: '/etat-session-formation',
  getExportParticipantsFormation:
    'formations/session/export-participants/:sessionId',

  postAnnulerSession: '/formations/session/annuler/:sessionId',
  postCloturerInscription: '/formations/session/cloturer/:sessionId',
  postFinaliserSession: '/formations/session/finaliser/:sessionId',
  postAjoutManuelSession: '/formations/session/ajouter-agent/:sessionId',
  deleteAjoutManuelSession: '/formations/session/retirer-agent/:sessionId',

  postInscrireAutoFormation: '/formations/foad/init/:foadId',
  postUpdateAutoFormation: '/formations/foad/update/:foadId',
  postFinSuiviAutoFormation: '/formations/foad/fin-suivi/:foadId',
  getFoad: '/formations/foad/:foadId',

  getTuteurs: '/formations/tutorat/tuteurs',
  getTutorat: '/formations/tutorat/:tutoratId',
  postCreateTutoratAgent: '/formations/tutorat/:agentId',
  postUpdateTutoratAgent: '/formations/tutorat/update/:tutoratId',

  postCreateEquivalenceAgent: '/formations/equivalence/:agentId/:moduleId',
  postUpdateEquivalenceAgent: '/formations/equivalence/update/:equivalenceId',

  postInscriptionSessionAgent: '/formations/inscription/session/:agentId',
  postUpdateSessionAgent:
    '/formations/inscription/session/update/:inscriptionId/:newSessionId',
  postBasculerEnEquivalence:
    '/formations/inscription/basculer-en-equivalence/:inscriptionId',
  postBasculerEnSession:
    '/formations/equivalence/basculer-en-session/:equivalenceId',

  getSessionsFormation: '/formations/session/module/:moduleId',

  postFinaliserInscriptionAgent:
    '/formations/inscription/finaliser/:inscriptionId',
  postReBasculerPermierChoixAgent:
    '/formations/inscription/rebasculer-en-premier-choix/:inscriptionId',
  postSupprimerPremierChoixAgent:
    '/formations/inscription/supprimer-premier-choix/:inscriptionId',

  postInscriptionsSemainesMetierSemaineApprofondissementSemaineTechnique:
    '/formations/inscription/session/semaine-metier-semaine-approfondissement-semaine-technique/:agentId',
  getDemandeInscription: '/formations/inscription/demande/:inscriptionId',
  postRepondreDemande:
    '/formations/inscription/repondre-demande/:inscriptionId',
  getDemandeTransfert: '/formations/inscription/transfert/:inscriptionId',
  postDemandeTransfert:
    '/formations/inscription/transfert/repondre-demande/:inscriptionId',

  postDemanderTransfert: '/formations/inscription/transfert/:inscriptionId',
  postTransfertSession:
    '/formations/inscription/transfert/:inscriptionId/:newSessionId',
  annulerInscription: '/formations/inscription/annuler/:inscriptionId',

  aTraiter: {
    agentsAInscrirePriseDePoste: '/formations/agents-a-inscrire-prise-de-poste',
    agentsAInscrireModuleAgricole:
      '/formations/agents-a-inscrire-module-agricole',
    agentsAInscrireModuleTutorat:
      '/formations/agents-a-inscrire-module-tutorat',
    agentsAInscrireSemaineConsolidation:
      '/formations/agents-a-inscrire-semaine-consolidation',
    transfertRefuses: '/formations/inscription/transfert/demandes-refusees',
    demandesInscriptions: '/formations/inscription/demandes',
    equivalencesAValider: '/formations/equivalence/a-valider',
    repondreDemandeEquivalence:
      '/formations/equivalence/repondre-demande/:equivalenceId',
    inscriptionsRefusees: '/formations/inscription/refusees',
    transfertsSessionDemandes: '/formations/inscription/transfert',
    // same route as transfertsSessionDemandes but we don't display the same columns in the view
    // so in case it changes in the future, it's better to use two variables
    demandesDeTransfert: '/formations/inscription/transfert',
    prestationsDeSermentARenseigner: '/prestations-serment/a-renseigner',
    prestationsDeSermentAValider: '/prestations-serment/a-valider',
    prestationsDeSermentRefusees: '/prestations-serment/refusees',
  },
  enCoursDeTraitement: {
    demandesDeTransfert:
      '/formations/inscription/transfert/en-cours-de-traitement',
  },
  getHistoriqueFormationAgentForCursus:
    '/formations/historique/:agentId/:cursusId',
};

export const agentRoutesAPI = {
  getFicheAgent: '/agent/:agentId',
  getFicheAgentWithoutPoste: '/agent/sans-poste/:agentId',
  getBordereauRecapitulatif: '/agent/bordereau-recapitulatif/:agentId',
  getDernierPosteAgent: '/dernier-poste-of-agent/:agentId',
  getDernierPosteQuitteAgent: '/dernier-poste-quitte-of-agent/:agentId',
  getCursusAgent: '/agent/formations/cursus/:agentId',
  getNotesAgent: '/agent/notes/:agentId',
  getNbNotesOfAgent: '/agent/notes/:agentId/nb-notes',
  exportNotesOfAgents: '/agent/notes/:agentId/export',
  getModuleParCursusAgent: '/agent/formations/modules/:agentId/:cursusId',
  getAgentsNonAffectes: '/agent/affectation/non-affectes-30-jours',
  getAgentsLesArrivees: '/agent/affectation/les-arrivees',
  postArriveeAgent: '/poste/enregistrer-arrivee/:agentId',
  postAjouterNote: '/agent/notes/:agentId',
  getPhoto: '/agent/photo/:agentId',
};

export const habilitationRoutesAPI = {
  aValider: 'habilitations/a-valider/:agentId',
  valider: 'habilitations/valider/:agentId',
  getHabilitationsAgent: 'habilitations/show/:agentId',
};

export const commissionnementRoutesAPI = {
  aTraiter: {
    getCommissionnementsPropositions: '/commissionnements/propositions',
    getCommissionnementsChangementPropositions:
      '/commissionnements/actions/demandes-changements-commissionnement',
  },
  getProposerCommissionnement:
    '/commissionnements/proposer-commissionnement/:agentId',
  getPropositionCommissionnement: '/commissionnements/proposition/:actionId',
  // get
  getDataForPropositionCommissionnementChangement:
    '/commissionnements/actions/data-for-demande-changement-commissionnement/:agentId',
  getPropositionCommissionnementChangement:
    '/commissionnements/actions/demande-changement-commissionnement/:actionId',
  postProposerCommissionnement:
    '/commissionnements/proposer-commissionnement/:agentId',
  postProposerCommissionnementChangement:
    '/commissionnements/actions/proposer-changement-commissionnement/:agentId',
  postValidationPropositionCommissionnement:
    '/commissionnements/repondre-proposition/:actionId',
  postValidationPropositionCommissionnementChangement:
    '/commissionnements/actions/repondre-demande-changement-commissionnement/:actionId',
  getCommissionnementsAgent: '/commissionnements/of-agent/:agentId',
  postRenseignerPrestationDeSerment:
    '/prestations-serment/renseigner-prestation-serment/:agentId',
  postValiderPrestationDeSerment:
    '/prestations-serment/repondre-prestation-serment/:prestationSermentId',
  postImprimerBordereau:
    '/cartes-commissionnement/imprimer-bordereau/:bordereauId',
  getInfosForDemandeAgents:
    '/commissionnements/actions/data-for-demande-for-agent/:agentId',
  postChangementDeZone: '/commissionnements/actions/changement-zone/:agentId',
  postDecommissionnement:
    '/commissionnements/actions/decommissionnement/:agentId',
  getValiderRenouvellementCarte:
    '/cartes-commissionnement/cartes-a-renouveler/:carteId',
  postValiderRenouvellementCarte:
    '/cartes-commissionnement/renouveler-carte/:carteId',
  getInfosForDeclarerCartePerdue:
    '/cartes-commissionnement/declarer-carte-perdue/:agentId',
  postCartePerdue: '/cartes-commissionnement/declarer-carte-perdue/:agentId',
  getValiderDestructionCarte:
    '/cartes-commissionnement/cartes-a-detruire/:carteId',
  postValiderDestructionCarte:
    '/cartes-commissionnement/detruire-carte/:carteId',
  postValiderCarteNonRendue:
    '/cartes-commissionnement/carte-non-rendue/:carteId',
  updatePhoto: '/cartes-commissionnement/agent/photo/:carteId',
};

export const arretesRoutesAPI = {
  postRefuserAction:
    '/arretes-nomination/refuser-action-commissionnement/:actionId',
  postEditerArreteCommissionnement:
    '/arretes-nomination/editer-arrete/:typeCommissionnementId',
  postUpdateNumeroNorArreteCommissionnement:
    '/arretes-nomination/update-numero-nor/:idArreteNomination',
  getArreteCommissionnementAEditer:
    '/arretes-nomination/agents-for-arrete-a-editer/:typeCommissionnementId',
  getArreteCommissionnementEdites:
    '/arretes-nomination/edites/:typeCommissionnementId',
  getArreteCommissionnementNotifies:
    '/arretes-nomination/notifies/:typeCommissionnementId',
  getCommissionnementsForArreteEdite:
    '/arretes-nomination/agents-for-arrete-edite/:arreteNominationId',
  getCommissionnementsForArreteNotifie:
    '/arretes-nomination/agents-for-arrete-notifie/:arreteNominationId',
  getArreteNominationEditesExport:
    '/arretes-nomination/export-arrete-edite/:arreteNominationId',
  getArreteNominationAEditerExport:
    '/arretes-nomination/export-arrete-a-editer/:typeCommissionnementId',
  postNotifierArreteNomination:
    '/arretes-nomination/notifier-agents/:idArreteNomination',
  getArreteNominationPdf: '/arretes-nomination/pdf/:idArreteNomination',
  getArreteNominationPdfForHistorique:
    '/arretes-nomination/pdf-for-historique-commissionnement/:idHistorique',
  getCommissionnementsEnAttente:
    '/arretes-nomination/commissionnements-en-attente',
  getDecommissionnementsEnAttente:
    '/arretes-nomination/decommissionnements-en-attente',
};

export const suiviActiviteRoutesAPI = {
  getObjetsAction: '/suivi-activite/objets-action',
  getTypesAction: '/suivi-activite/types-action',
  getSuiviActivite: '/suivi-activite',
  exportSuiviActivite: '/suivi-activite/export',
};

export const aTraiterSuperieurDeuxMoisRouteAPI = '/actions-a-traiter-2-mois';

export const messageADiffuserRouteApi = 'message-a-diffuser';

export const stats = {
  getAgentsCommissionnes: 'stats/agents-commissionnes',
  exportAgentsCommissionnes: 'stats/export-agents-commissionnes',
  getAgentsCommissionnesAnnee: 'stats/agents-commissionnes-annee',
  exportAgentsCommissionnesAnnee: 'stats/export-agents-commissionnes-annee',
  getAgentsCommissionnesStatut: 'stats/agents-commissionnes-statut',
  exportAgentsCommissionnesStatut: 'stats/export-agents-commissionnes-statut',
  getAgentsDecommissionnesAnnee: 'stats/agents-decommissionnes-annee',
  exportAgentsDecommissionnesAnnee: 'stats/export-agents-decommissionnes-annee',
  getAgentsZoneChangeeAnnee: 'stats/agents-zone-changee-annee',
  exportAgentsZoneChangeeAnnee: 'stats/export-agents-zone-changee-annee',
  getAgentsChangementCommissionnementAnnee:
    'stats/agents-changement-commissionnement-annee',
  exportAgentsChangementCommissionnementAnnee:
    'stats/export-agents-changement-commissionnement-annee',
  getAgentsCommissionnesHabilitation: 'stats/agents-commissionnes-habilitation',
  exportAgentsCommissionnesHabilitation:
    'stats/export-agents-commissionnes-habilitation',
  getAgentsSuiviFormationsAnnee: 'stats/prise-de-poste/suivi-annee',
  exportAgentsSuiviFormationsAnnee: 'stats/prise-de-poste/export-suivi-annee',
  getAgentsTBSuiviPDP: 'stats/prise-de-poste/tb-suivi',
  exportAgentsTBSuiviPDP: 'stats/prise-de-poste/export-tb-suivi',
  exportAgentsSuiviRepartitionPDP:
    'stats/prise-de-poste/export-repartition-suivi',
  getAgentsNonInscrits: 'stats/prise-de-poste/agents-non-inscrits',
  exportSyntheseAgentsNonInscrits:
    'stats/prise-de-poste/export-agents-non-inscrits',
  exportListeAgentsNonInscrits:
    'stats/prise-de-poste/export-liste-agents-non-inscrits',
};

export const refuserCartesAEditer = '/cartes-commissionnement/refuser';

export const getEditionsCartesRefusees =
  '/cartes-commissionnement/editions-cartes-refusees';

export const notesRoutesAPI = {
  getAgentsAvecNote: '/agent/notes/agents-avec-note/:typeCommissionnementId',
  exportAgentsAvecNote:
    '/agent/notes/export-agents-avec-note/:typeCommissionnementId',
};
