import axios from 'axios';

class ApiService {
  fetchData(url, callback) {
    this.fetchDataWithParam(url, {}, callback);
  }

  fetchDataWithParam(url, params, callback) {
    axios
      .get(url, { params: params })
      .then((response) => {
        callback(response.data.data);
      })
      .catch((err) => {
        console.log('error', err);
      });
  }

  checkFailed(then) {
    return function (responses) {
      let responsesTab = [];
      responses.forEach((response) => {
        if (response.error) {
          responsesTab.push(null);
        } else {
          responsesTab.push(response);
        }
      });
      return then(responsesTab);
    };
  }
}

export default new ApiService();
