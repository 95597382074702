import axios from 'axios';
import { saveAs } from 'file-saver';
import { arretesRoutesAPI, configurationRoutesAPI } from '../configs/routesAPI';

class PdfHelper {
  downloadFichePoste = (posteId, event) => {
    event.preventDefault();
    axios({
      url: '/poste/fiche-poste/' + posteId,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (200 === response.status) {
          const blob = new Blob([response.data], {
            type: 'application/pdf;',
          });
          saveAs(blob, `Fiche_poste_${posteId}.pdf`);
          return;
        }

        console.log('error', { response }); // TODO handle error
      })
      .catch((error) => {
        console.log({ error }); // TODO handle error
      });
  };

  downloadManuelUtilisateur = (role, event) => {
    event.preventDefault();
    axios({
      url: configurationRoutesAPI.getManuelUtilisateur.replace(':role', role),
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (200 === response.status) {
          const filename = this.getFilename(
            response,
            'Commissionnement-Manuel_' + role + '.pdf'
          );

          const blob = new Blob([response.data], {
            type: 'application/pdf;',
          });

          saveAs(blob, filename);
          return;
        }

        console.log('error', { response }); // TODO handle error
      })
      .catch((error) => {
        console.log({ error }); // TODO handle error
      });
  };

  getFilename = (response, defaultFilename) => {
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
      if (filenameMatch && filenameMatch.length > 1) {
        return filenameMatch[1];
      }
    }

    return defaultFilename;
  };

  downloadJustificatifNote = (noteId, filename, event) => {
    event.preventDefault();
    axios({
      url: '/agent/notes/justificatif/' + noteId,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (200 === response.status) {
          const blob = new Blob([response.data], {
            type: response.data.type,
          });
          saveAs(blob, `Justificatif_note_${filename}`);
          return;
        }

        console.log('error', { response }); // TODO handle error
      })
      .catch((error) => {
        console.log({ error }); // TODO handle error
      });
  };

  downloadJustificatifFOAD = (foadId, event) => {
    event.preventDefault();
    axios({
      url: '/formations/foad/justificatif/' + foadId,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (200 === response.status) {
          const blob = new Blob([response.data], {
            type: 'application/pdf;',
          });
          saveAs(blob, `Justificatifs_FOAD.pdf`);
          return;
        }

        console.log('error', { response }); // TODO handle error
      })
      .catch((error) => {
        console.log({ error }); // TODO handle error
      });
  };

  downloadJustificatifHabilitation = (agentHabilitationId, filename) => {
    axios({
      url: '/habilitations/justificatif/' + agentHabilitationId,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf;',
      });
      let name = filename ? filename + '.pdf' : 'Justificatif_habilitation.pdf';
      saveAs(blob, name);
    });
  };

  downloadPieceJointeActionEnCours = (actionEnCoursId, filename) => {
    axios({
      url: '/commissionnements/actions/pdf/' + actionEnCoursId,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf;',
      });
      let name = filename ? filename + '.pdf' : 'justificatif.pdf';
      saveAs(blob, name);
    });
  };

  downloadPrestationDeSerment = (prestationSermentId, filename) => {
    axios({
      url: '/prestations-serment/pdf/' + prestationSermentId,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf;',
      });
      let name = filename ? filename + '.pdf' : 'prestation_de_serment.pdf';
      saveAs(blob, name);
    });
  };

  downloadArreteNomination = (
    arreteNominationId,
    numeroNor,
    event,
    historiqueId = null
  ) => {
    const url =
      null !== historiqueId
        ? arretesRoutesAPI.getArreteNominationPdfForHistorique.replace(
            ':idHistorique',
            historiqueId
          )
        : arretesRoutesAPI.getArreteNominationPdf.replace(
            ':idArreteNomination',
            arreteNominationId
          );

    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (200 === response.status) {
          const blob = new Blob([response.data], {
            type: 'application/pdf;',
          });
          saveAs(blob, `${numeroNor}.pdf`);
          return;
        }

        console.log('error', { response }); // TODO handle error
      })
      .catch((error) => {
        console.log({ error }); // TODO handle error
      });
  };

  downloadCartesBordereau = (bordereauId, filename, event) => {
    event.preventDefault();
    axios({
      url: '/cartes-commissionnement/pdf-bordereau/' + bordereauId,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        if (200 === response.status) {
          const blob = new Blob([response.data], {
            type: 'application/pdf;',
          });
          saveAs(blob, filename);
          return;
        }

        console.log('error', { response }); // TODO handle error
      })
      .catch((error) => {
        console.log({ error }); // TODO handle error
      });
  };
}

export default new PdfHelper();
