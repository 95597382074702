import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import TableWithLoader from '../../../components/shared/tables/TableWithLoader';
import DateHelper from '../../../helpers/DateHelper';
import PdfHelper from '../../../helpers/PdfHelper';

export default function ConsulterPrestationsDeSermentEnAttenteTable({
  agents,
  isLoading,
}) {
  const columns = [
    {
      name: 'nom_affichage',
      label: 'Nom',
    },
    {
      name: 'prenom',
      label: 'Prénom',
    },
    {
      name: 'service',
      label: 'Service',
    },
    {
      name: 'date_prestation',
      label: 'Date de prestation',
      render: ({ date_prestation }) => DateHelper.format(date_prestation),
    },
    {
      name: 'date_demande',
      label: 'Date demande',
      render: ({ date_demande }) => DateHelper.format(date_demande),
    },
    {
      name: 'PS',
      label: 'PS',
      render: ({ prestation_serment_id, agent_id }) => {
        return (
          <Link
            title="Télécharger la prestation de serment"
            aria-label="Télécharger la prestation de serment"
            className="btn-action-trigger fr-app-btn-icon no-external-link-icon"
            onClick={() =>
              PdfHelper.downloadPrestationDeSerment(
                prestation_serment_id,
                agent_id
              )
            }
            to={''}
            download
          >
            <span
              aria-hidden="true"
              className="fr-app-icon fr-app-icon-file-PDF"
            />
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <div className="fr-app-table">
        <caption style={{ display: 'block' }}>
          Liste des prestations de serment en attente de validation
        </caption>
      </div>
      <TableWithLoader
        data={agents}
        columns={columns}
        isLoading={isLoading}
        rowKey="id"
        customClassName="prestation-serment-a-valider-tab no-caption"
      ></TableWithLoader>
    </>
  );
}

ConsulterPrestationsDeSermentEnAttenteTable.propTypes = {
  agents: PropTypes.array,
  isLoading: PropTypes.bool,
};
