import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { SideMenu, SideMenuItem, Text } from '@dataesr/react-dsfr';
import UserService from '../services/user.service';
import {
  agentRoutes,
  archivesRoutes,
  aTraiter,
  commissionnementsRoutes,
  enCoursDeTraitement,
  formationsRoutes,
  suiviActivite,
  aTraiterSuperieurDeuxMois,
  statistiquesRoutes,
} from '../configs/routes';
import DernieresFichesConsulteesService from '../services/dernieresfichesconsultees.service';
import { commissionnementArretesAction } from '../enums/Enums';
import { roleEnum } from '../enums/RoleEnum';
import StatsAccessService from '../services/statsaccess.service';
import UserProfileService from '../services/userprofile.service';

export default function MainNav() {
  const navRef = useRef(null);
  const location = useLocation(); //assigning location variable
  const { pathname } = location; //destructuring pathname from location
  const splitLocation = pathname.split('/'); //Javascript split method to get the name of the path in array

  const dernieresFichesConsultees = DernieresFichesConsulteesService.get();

  let navigate = useNavigate();
  const redirectToAgentsListe = () => {
    navigate('/agents/liste');
  };

  useEffect(() => {
    navRef.current.setAttribute('role', 'navigation');
    navRef.current.setAttribute('aria-label', 'Menu principal');
  }, []);

  return (
    <SideMenu
      ref={navRef}
      className="fr-app-main-nav"
      buttonLabel="Menu"
      id="main-nav"
    >
      {false === UserService.isConsultant() ? (
        <li
          className={
            splitLocation[1] === 'atraiter'
              ? 'fr-sidemenu__item fr-app-menu-item on'
              : 'fr-sidemenu__item fr-app-menu-item off'
          }
        >
          <NavLink
            className="fr-sidemenu__link fr-app-menu-link fr-app-btn-icon"
            to={aTraiter.base}
          >
            <span className="ri-home-2-line" /> A traiter
          </NavLink>
        </li>
      ) : (
        ''
      )}
      <SideMenuItem
        title="Agents"
        expandedDefault={splitLocation[1] === 'agents' ? true : false}
        className={
          splitLocation[1] === 'agents'
            ? 'fr-sidemenu__item fr-app-menu-item on'
            : 'fr-sidemenu__item fr-app-menu-item off'
        }
      >
        <li
          className={
            splitLocation[2] === 'liste' ||
            splitLocation[3] === 'consulteragent' ||
            splitLocation[3] === 'consultercommissionnements' ||
            splitLocation[3] === 'consulterpostes' ||
            splitLocation[3] === 'consulterformations' ||
            splitLocation[3] === 'consulterhabilitations' ||
            splitLocation[3] === 'consulternotes'
              ? 'fr-sidemenu__item fr-app-menu-item on'
              : 'fr-sidemenu__item fr-app-menu-item off'
          }
          onClick={redirectToAgentsListe}
        >
          <NavLink
            className={
              splitLocation[2] === 'liste' ||
              splitLocation[3] === 'consulteragent' ||
              splitLocation[3] === 'consultercommissionnements' ||
              splitLocation[3] === 'consulterpostes' ||
              splitLocation[3] === 'consulterformations' ||
              splitLocation[3] === 'consulterhabilitations' ||
              splitLocation[3] === 'consulternotes'
                ? 'fr-sidemenu__link fr-app-menu-link active'
                : 'fr-sidemenu__link fr-app-menu-link'
            }
            to={
              splitLocation[3] === 'consulteragent'
                ? agentRoutes.consulter
                : splitLocation[3] === 'consultercommissionnements'
                ? agentRoutes.consultercommissionnements
                : splitLocation[3] === 'consulterpostes'
                ? agentRoutes.consulterPostes
                : splitLocation[3] === 'consulterformations'
                ? agentRoutes.consulterformations
                : splitLocation[3] === 'consulterhabilitations'
                ? agentRoutes.consulterhabilitations
                : splitLocation[3] === 'consulternotes'
                ? agentRoutes.consulterNotes
                : agentRoutes.list
            }
          >
            {UserService.isConsultant()
              ? 'Rechercher un agent'
              : 'Rechercher / créer un agent'}
          </NavLink>
        </li>
        {UserService.isAdministrateur() || UserService.isGestionnaire() ? (
          <>
            <li
              className={
                splitLocation[1] === 'departs'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={agentRoutes.listeDepart}
              >
                Les départs à venir
              </NavLink>
            </li>
            {UserService.isAdministrateur() ? (
              <>
                <li>
                  <NavLink
                    className="fr-sidemenu__link fr-app-menu-link"
                    to={agentRoutes.listeAgentsNonAffecte}
                  >
                    Les agents non affectés {'>'} 30 jours
                  </NavLink>
                </li>
              </>
            ) : (
              <li>
                <NavLink
                  className={
                    splitLocation[2] === 'lesarrivees'
                      ? 'fr-sidemenu__link fr-app-menu-link active'
                      : 'fr-sidemenu__link fr-app-menu-link'
                  }
                  to={agentRoutes.listeArriveesAgents}
                >
                  Les arrivées / agents non affectés
                </NavLink>
              </li>
            )}
            <li
              className={
                splitLocation[2] === 'fichesdeposte'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={agentRoutes.listeFichesPoste}
              >
                Fiches de poste
              </NavLink>
            </li>
          </>
        ) : (
          ' '
        )}
      </SideMenuItem>

      <li
        className={
          splitLocation[2] === 'sessions'
            ? 'fr-sidemenu__item fr-app-menu-item on'
            : 'fr-sidemenu__item fr-app-menu-item off'
        }
      >
        <NavLink
          className="fr-sidemenu__link fr-app-menu-link formation__link"
          to={formationsRoutes.list}
        >
          Formations
        </NavLink>
      </li>

      <SideMenuItem
        title="Commissionnements"
        expandedDefault={
          splitLocation[1] === 'commissionnements' ? true : false
        }
        className={
          splitLocation[1] === 'commissionnements'
            ? 'fr-sidemenu__item fr-app-menu-item on'
            : 'fr-sidemenu__item fr-app-menu-item off'
        }
      >
        {UserService.isAdministrateur() ? (
          <SideMenuItem
            expandedDefault={
              splitLocation[2] === 'arretesnomination' ? true : false
            }
            className={
              splitLocation[2] === 'arretesnomination'
                ? 'fr-app-menu-item on'
                : 'fr-app-menu-item off'
            }
            title="Arrêtés de nomination"
          >
            <li
              className={
                splitLocation[3] === 'editer'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={commissionnementsRoutes.arretesNomination.replace(
                  ':action',
                  commissionnementArretesAction.ARRETES_A_EDITER
                )}
              >
                À éditer
              </NavLink>
            </li>
            <li
              className={
                splitLocation[2] ===
                commissionnementArretesAction.ARRETES_EDITES_AVEC_NOR
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={commissionnementsRoutes.arretesNomination.replace(
                  ':action',
                  commissionnementArretesAction.ARRETES_EDITES_AVEC_NOR
                )}
              >
                Édités avec n° NOR
              </NavLink>
            </li>
            <li
              className={
                splitLocation[2] === 'notifies'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={commissionnementsRoutes.arretesNomination.replace(
                  ':action',
                  commissionnementArretesAction.ARRETES_NOTIFIES
                )}
              >
                Notifiés
              </NavLink>
            </li>
          </SideMenuItem>
        ) : (
          <></>
        )}
        {!UserService.isConsultant() ? (
          <li
            className={
              splitLocation[1] === 'habilites'
                ? 'fr-sidemenu__item fr-app-menu-item on'
                : 'fr-sidemenu__item fr-app-menu-item off'
            }
          >
            <NavLink
              className="fr-sidemenu__link fr-app-menu-link"
              to={commissionnementsRoutes.agentsHabilitesList}
            >
              Agents habilités
            </NavLink>
          </li>
        ) : (
          <></>
        )}
        <li
          className={
            splitLocation[1] === 'commissionnes'
              ? 'fr-sidemenu__item fr-app-menu-item on'
              : 'fr-sidemenu__item fr-app-menu-item off'
          }
        >
          <NavLink
            className="fr-sidemenu__link fr-app-menu-link"
            to={commissionnementsRoutes.agentsCommissionnesList}
          >
            Agents commissionnés
          </NavLink>
        </li>
        {UserService.isAdministrateur() ? (
          <li
            className={
              splitLocation[1] === 'commissionnes'
                ? 'fr-sidemenu__item fr-app-menu-item on'
                : 'fr-sidemenu__item fr-app-menu-item off'
            }
          >
            <NavLink
              className="fr-sidemenu__link fr-app-menu-link"
              to={commissionnementsRoutes.bordereauxCartesAEditer}
            >
              Bordereaux et cartes
            </NavLink>
          </li>
        ) : (
          <></>
        )}
      </SideMenuItem>
      {UserService.isAdministrateur() ? (
        <SideMenuItem
          title="Archives"
          expandedDefault={
            splitLocation[1] === 'retraites' || splitLocation[1] === 'archives'
              ? true
              : false
          }
          className={
            splitLocation[1] === 'retraites' || splitLocation[1] === 'archives'
              ? 'fr-sidemenu__item fr-app-menu-item on'
              : 'fr-sidemenu__item fr-app-menu-item off'
          }
        >
          <li
            className={
              splitLocation[2] === 'liste'
                ? 'fr-sidemenu__item fr-app-menu-item on'
                : 'fr-sidemenu__item fr-app-menu-item off'
            }
          >
            <NavLink
              className="fr-sidemenu__link fr-app-menu-link"
              to={archivesRoutes.agentRetraitesList}
            >
              Agents retraités
            </NavLink>
          </li>
          <li
            className={
              splitLocation[2] === 'liste'
                ? 'fr-sidemenu__item fr-app-menu-item on'
                : 'fr-sidemenu__item fr-app-menu-item off'
            }
          >
            <NavLink
              className="fr-sidemenu__link fr-app-menu-link"
              to={archivesRoutes.agentArchivesList}
            >
              Agents archivés
            </NavLink>
          </li>
        </SideMenuItem>
      ) : (
        ''
      )}
      {UserService.hasEffectiveRole(roleEnum.ADMINISTRATEUR_GENERAL) ? (
        <li
          className={
            splitLocation[1] === 'suiviactivite'
              ? 'fr-sidemenu__item fr-app-menu-item on'
              : 'fr-sidemenu__item fr-app-menu-item off'
          }
        >
          <NavLink
            className="fr-sidemenu__link fr-app-menu-link suiviactivite__link"
            to={suiviActivite}
          >
            Suivi d'activité
          </NavLink>
        </li>
      ) : (
        ''
      )}
      {UserService.isGestionnaire() ? (
        <li
          className={
            splitLocation[1] === 'encoursdetraitement'
              ? 'fr-sidemenu__item fr-app-menu-item on'
              : 'fr-sidemenu__item fr-app-menu-item off'
          }
        >
          <NavLink
            className="fr-sidemenu__link fr-app-menu-link fr-app-btn-icon"
            to={enCoursDeTraitement.base}
          >
            En cours de traitement
          </NavLink>
        </li>
      ) : (
        ''
      )}

      {/* Stats */}
      {UserService.isAdministrateur() &&
      !UserProfileService.isAdminGenEauNature() ? (
        <SideMenuItem
          title="Statistiques"
          expandedDefault={splitLocation[1] === 'statistiques' ? true : false}
          className={
            splitLocation[1] === 'statistiques'
              ? 'fr-sidemenu__item fr-app-menu-item on'
              : 'fr-sidemenu__item fr-app-menu-item off'
          }
        >
          {/* Agents commissionnés */}
          {StatsAccessService.canAccessAgentsCommissionnes() ? (
            <li
              className={
                splitLocation[2] === 'agentscommissionnes'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={statistiquesRoutes.agentsCommissionnesList}
              >
                Agents commissionnés
              </NavLink>
            </li>
          ) : (
            <></>
          )}

          {/* Agents commissionnés année */}
          {StatsAccessService.canAccessAgentsCommissionnesAnnee() ? (
            <li
              className={
                splitLocation[2] === 'agentscommissionnesannee'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={statistiquesRoutes.agentsCommissionnesAnneeList}
              >
                Agents commissionnés par année
              </NavLink>
            </li>
          ) : (
            <></>
          )}

          {/* Agents commissionnés statut */}
          {StatsAccessService.canAccessAgentsCommissionnesStatut() ? (
            <li
              className={
                splitLocation[2] === 'agentscommissionnesstatut'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={statistiquesRoutes.agentsCommissionnesStatutList}
              >
                Agents commissionnés par statut
              </NavLink>
            </li>
          ) : (
            <></>
          )}

          {/* Agents décommissionnés année */}
          {StatsAccessService.canAccessAgentsDecommissionnesAnnee() ? (
            <li
              className={
                splitLocation[2] === 'agentsdecommissionnesannee'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={statistiquesRoutes.agentsDecommissionnesAnneeList}
              >
                Agents décommissionnés par année
              </NavLink>
            </li>
          ) : (
            <></>
          )}

          {/* Agents zone changée année */}
          {StatsAccessService.canAccessAgentsZoneChangeeAnnee() ? (
            <li
              className={
                splitLocation[2] === 'agentszonechangeeannee'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={statistiquesRoutes.agentsZoneChangeeAnneeList}
              >
                Agents zone changée par année
              </NavLink>
            </li>
          ) : (
            <></>
          )}

          {/* Agents changement commissionnement année */}
          {StatsAccessService.canAccessAgentsChangementCommissionnementAnnee() ? (
            <li
              className={
                splitLocation[2] === 'agentschangementcommissionnementannee'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={
                  statistiquesRoutes.agentsChangementCommissionnementAnneeList
                }
              >
                Agents changement de commissionnement par année
              </NavLink>
            </li>
          ) : (
            <></>
          )}

          {/* Agents commissionnés par habilitation */}
          {StatsAccessService.canAccessAgentsCommissionnesHabilitation() ? (
            <li
              className={
                splitLocation[2] === 'agentscommissionneshabilitation'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={statistiquesRoutes.agentsCommissionnesHabilitationList}
              >
                Agents commissionnés par habilitation
              </NavLink>
            </li>
          ) : (
            <></>
          )}

          {/* Agents suivi formation année */}
          {StatsAccessService.canAccessAgentsSuiviFormationsAnnee() ? (
            <li
              className={
                splitLocation[2] === 'agentssuiviformationsannee'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={statistiquesRoutes.agentsSuiviFormationsAnneeList}
              >
                Suivi formations agents par année
              </NavLink>
            </li>
          ) : (
            <></>
          )}

          {/* Agents TB suivi prise de poste */}
          {StatsAccessService.canAccessAgentsTBSuiviPDP() ? (
            <li
              className={
                splitLocation[2] === 'agentstbsuivipdp'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={statistiquesRoutes.agentsTBSuiviPDPList}
              >
                TB suivi de prise de poste
              </NavLink>
            </li>
          ) : (
            <></>
          )}

          {/* Agents suivi répartition de prise de poste */}
          {StatsAccessService.canAccessAgentsSuiviRepartitionPDP() ? (
            <li
              className={
                splitLocation[2] === 'agentssuivirepartitionpdp'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={statistiquesRoutes.agentsSuiviRepartitionPDPList}
              >
                Suivi répartition prise de poste
              </NavLink>
            </li>
          ) : (
            <></>
          )}

          {/* Agents non inscrits */}
          {StatsAccessService.canAccessAgentsNonInscritsAnnee() ? (
            <li
              className={
                splitLocation[2] === 'agentsnoninscritsannee'
                  ? 'fr-sidemenu__item fr-app-menu-item on'
                  : 'fr-sidemenu__item fr-app-menu-item off'
              }
            >
              <NavLink
                className="fr-sidemenu__link fr-app-menu-link"
                to={statistiquesRoutes.agentsNonInscritsAnneeList}
              >
                Agents non inscrits SM/SA/ST/SC
              </NavLink>
            </li>
          ) : (
            <></>
          )}
        </SideMenuItem>
      ) : (
        ''
      )}

      {UserService.isAdministrateur() ? (
        <li
          className={
            splitLocation[1] === 'atraitersuperieurdeuxmois'
              ? 'fr-sidemenu__item fr-app-menu-item on'
              : 'fr-sidemenu__item fr-app-menu-item off'
          }
        >
          <NavLink
            className="fr-sidemenu__link fr-app-menu-link fr-app-btn-icon"
            to={aTraiterSuperieurDeuxMois.base}
          >
            A traiter Régions {'>'} 2 mois
          </NavLink>
        </li>
      ) : (
        ''
      )}
      {/* TODO hidden for release */}
      {/*<SideMenuItem*/}
      {/*  title="Style Guide"*/}
      {/*  expandedDefault={splitLocation[1] === 'styleguide' ? true : false}*/}
      {/*  className={*/}
      {/*    splitLocation[1] === 'styleguide'*/}
      {/*      ? 'fr-sidemenu__item fr-app-menu-item on' : 'fr-sidemenu__item fr-app-menu-item off'*/}
      {/*  }*/}
      {/*>*/}
      {/*  <li*/}
      {/*    className={*/}
      {/*      splitLocation[2] === 'general'*/}
      {/*        ? 'fr-sidemenu__item fr-app-menu-item on' : 'fr-sidemenu__item fr-app-menu-item off'*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <NavLink*/}
      {/*      className="fr-sidemenu__link fr-app-menu-link"*/}
      {/*      to="/styleguide/general"*/}
      {/*    >*/}
      {/*      Général*/}
      {/*    </NavLink>*/}
      {/*  </li>*/}
      {/*  <li*/}
      {/*    className={*/}
      {/*      splitLocation[2] === 'app-icpe'*/}
      {/*        ? 'fr-sidemenu__item fr-app-menu-item on' : 'fr-sidemenu__item fr-app-menu-item off'*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <NavLink*/}
      {/*      className="fr-sidemenu__link fr-app-menu-link"*/}
      {/*      to="/styleguide/app-icpe"*/}
      {/*    >*/}
      {/*      App ICPE*/}
      {/*    </NavLink>*/}
      {/*  </li>*/}
      {/*  <li*/}
      {/*    className={*/}
      {/*      splitLocation[2] === 'app-eaunature'*/}
      {/*        ? 'fr-sidemenu__item fr-app-menu-item on' : 'fr-sidemenu__item fr-app-menu-item off'*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <NavLink*/}
      {/*      className="fr-sidemenu__link fr-app-menu-link"*/}
      {/*      to="/styleguide/app-eaunature"*/}
      {/*    >*/}
      {/*      App Eau et Nature*/}
      {/*    </NavLink>*/}
      {/*  </li>*/}
      {/*  <SideMenuItem className="fr-app-menu-item" title="Test Menu niveau 2">*/}
      {/*    <li className="fr-sidemenu__item fr-app-menu-item">*/}
      {/*      <NavLink className="fr-sidemenu__link fr-app-menu-link" to="/path">*/}
      {/*        Test Menu niveau 3*/}
      {/*      </NavLink>*/}
      {/*    </li>*/}
      {/*    <li className="fr-sidemenu__item fr-app-menu-item">*/}
      {/*      <NavLink className="fr-sidemenu__link fr-app-menu-link" to="/path">*/}
      {/*        Test Menu niveau 3*/}
      {/*      </NavLink>*/}
      {/*    </li>*/}
      {/*    <li className="fr-sidemenu__item fr-app-menu-item">*/}
      {/*      <NavLink className="fr-sidemenu__link fr-app-menu-link" to="/path">*/}
      {/*        Test Menu niveau 3*/}
      {/*      </NavLink>*/}
      {/*    </li>*/}
      {/*  </SideMenuItem>*/}
      {/*</SideMenuItem>*/}

      {0 !== dernieresFichesConsultees.length ? (
        <li className="fr-app-linklist-container with-description">
          <Text className="fr-app-linklist-label" size="md">
            Dernières fiches consultées :
          </Text>
          <ul className="fr-app-linklist">
            {dernieresFichesConsultees.map((fiche) => {
              return (
                <li key={fiche.id}>
                  <a href={agentRoutes.consulter.replace(':id', fiche.id)}>
                    <span className="ri-arrow-right-line" />
                    {''}
                    <span>{fiche.prenom + ' ' + fiche.nom}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </li>
      ) : (
        ''
      )}
    </SideMenu>
  );
}
