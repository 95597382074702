import React, { Component } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Col, Container, Row } from '@dataesr/react-dsfr';

import AppMainNav from './AppMainNav';
import AppBreadcrumb from './AppBreadcrumb';
import SwitchProfil from '../pages/profils/SwitchProfil';

import UserService from '../services/user.service';
import {
  agentsRoutesBaseName,
  archivesRoutesBaseName,
  aTraiterRoutesBaseName,
  commissionnementsRoutesBaseName,
  enCoursDeTraitementRoutesBaseName,
  formationsRoutesBaseName,
  gestionProfilsBaseName,
  lookupTablesRoutesBaseName,
  suiviActivite,
  aTraiterSuperieurDeuxMois,
  statistiquesRoutesBaseName,
  mentionsLegalesBaseName,
  accessibiliteBaseName,
  sitemapBaseName,
} from '../configs/routes';
import { roleEnum } from '../enums/RoleEnum';
import SuiviActivite from '../pages/suiviactivite/SuiviActivite';
import EnCoursDeTraitementRoutes from './routes/EnCoursDeTraitementRoutes';
import PagesATraiterSuperieurADeuxMois from '../pages/atraitersuperieurdeuxmois/PagesATraiterSuperieurADeuxMois';
import StatistiquesRoutes from './routes/StatistiquesRoutes';

const ParametrageRoutes = React.lazy(
  () => import('./routes/ParametrageRoutes')
);
const FormationsRoutes = React.lazy(() => import('./routes/FormationsRoutes'));
const ATraiterRoutes = React.lazy(() => import('./routes/ATraiterRoutes'));
const AgentsRoutes = React.lazy(() => import('./routes/AgentsRoutes'));
const AccessibiliteRoutes = React.lazy(
  () => import('./routes/AccessibiliteRoutes')
);
const SitemapRoutes = React.lazy(() => import('./routes/SitemapRoutes'));
const MentionsLegalesRoutes = React.lazy(
  () => import('./routes/MentionsLegalesRoutes')
);
const StyleGuideRoutes = React.lazy(() => import('./routes/StyleGuideRoutes'));
const ArchivesRoutes = React.lazy(() => import('./routes/ArchivesRoutes'));
const CommissionnementsRoutes = React.lazy(
  () => import('./routes/CommissionnementsRoutes')
);

export default class AppMain extends Component {
  render() {
    return (
      <Container>
        <>
          <Row>
            <Col
              n="12"
              className="fr-col-lg-4 fr-col-xl-3 fr-app-main-nav-container"
            >
              <AppMainNav />
            </Col>

            <Col
              n="12"
              className="fr-col-lg-8 fr-col-xl-9 fr-app-main-container"
            >
              <main role="main" id="contenu">
                <AppBreadcrumb />
                <>
                  <Routes>
                    {/* PARAMETRAGE DE L'APPLICATION */}
                    {UserService.isAdministrateur() ? (
                      <>
                        <Route
                          path={lookupTablesRoutesBaseName + '/*'}
                          element={
                            <React.Suspense fallback={<div>Loading....</div>}>
                              <ParametrageRoutes />
                            </React.Suspense>
                          }
                        />
                        <Route
                          path={archivesRoutesBaseName + '/*'}
                          element={
                            <React.Suspense fallback={<div>Loading....</div>}>
                              <ArchivesRoutes />
                            </React.Suspense>
                          }
                        />
                      </>
                    ) : (
                      ''
                    )}
                    {/* MODULE AGENTS */}
                    <Route
                      path={agentsRoutesBaseName + '/*'}
                      element={
                        <React.Suspense fallback={<div>Loading....</div>}>
                          <AgentsRoutes />
                        </React.Suspense>
                      }
                    />
                    {/* MODULE COMMISSIONNEMENTS */}
                    <Route
                      path={commissionnementsRoutesBaseName + '/*'}
                      element={
                        <React.Suspense fallback={<div>Loading....</div>}>
                          <CommissionnementsRoutes />
                        </React.Suspense>
                      }
                    />
                    {/* MODULE FORMATION */}
                    <Route
                      path={formationsRoutesBaseName + '/*'}
                      element={
                        <React.Suspense fallback={<div>Loading....</div>}>
                          <FormationsRoutes />
                        </React.Suspense>
                      }
                    />

                    {/* MODULE A TRAITER */}
                    {false === UserService.isConsultant() ? (
                      <Route
                        path={aTraiterRoutesBaseName + '/*'}
                        element={
                          <React.Suspense fallback={<div>Loading....</div>}>
                            <ATraiterRoutes />
                          </React.Suspense>
                        }
                      />
                    ) : (
                      ''
                    )}

                    {/* MODULE EN COURS DE TRAITEMENT */}
                    {UserService.isGestionnaire() ? (
                      <Route
                        path={enCoursDeTraitementRoutesBaseName + '/*'}
                        element={
                          <React.Suspense fallback={<div>Loading....</div>}>
                            <EnCoursDeTraitementRoutes />
                          </React.Suspense>
                        }
                      />
                    ) : (
                      ''
                    )}

                    {/* GESTION DES PROFILS */}
                    {UserService.canSwitchProfil() ? (
                      <Route
                        path={gestionProfilsBaseName}
                        element={
                          <React.Suspense fallback={<div>Loading....</div>}>
                            <SwitchProfil />
                          </React.Suspense>
                        }
                      />
                    ) : (
                      ''
                    )}

                    {/* SUIVI ACTIVITE */}
                    {UserService.hasEffectiveRole(
                      roleEnum.ADMINISTRATEUR_GENERAL
                    ) ? (
                      <Route
                        path={suiviActivite}
                        element={
                          <React.Suspense fallback={<div>Loading....</div>}>
                            <SuiviActivite />
                          </React.Suspense>
                        }
                      />
                    ) : (
                      ''
                    )}

                    {/* A traiter > 2 mois */}
                    {UserService.isAdministrateur() ? (
                      <Route
                        path={aTraiterSuperieurDeuxMois.base}
                        element={
                          <React.Suspense fallback={<div>Loading....</div>}>
                            <PagesATraiterSuperieurADeuxMois />
                          </React.Suspense>
                        }
                      />
                    ) : (
                      ''
                    )}

                    {UserService.isAdministrateur() ? (
                      <>
                        <Route
                          path={statistiquesRoutesBaseName + '/*'}
                          element={
                            <React.Suspense fallback={<div>Loading....</div>}>
                              <StatistiquesRoutes />
                            </React.Suspense>
                          }
                        />
                      </>
                    ) : (
                      ''
                    )}

                    {/* STYLE GUIDE -- uniquement pour le DEV */}
                    <Route
                      path="/styleguide/*"
                      element={
                        <React.Suspense fallback={<div>Loading....</div>}>
                          <StyleGuideRoutes />
                        </React.Suspense>
                      }
                    />
                    {/* PLAN DU SITE */}
                    <Route
                      path={sitemapBaseName + '/*'}
                      element={
                        <React.Suspense fallback={<div>Loading....</div>}>
                          <SitemapRoutes />
                        </React.Suspense>
                      }
                    />
                    {/* ACCESSIBILITE */}
                    <Route
                      path={accessibiliteBaseName + '/*'}
                      element={
                        <React.Suspense fallback={<div>Loading....</div>}>
                          <AccessibiliteRoutes />
                        </React.Suspense>
                      }
                    />
                    {/* MENTIONS LEGALES */}
                    <Route
                      path={mentionsLegalesBaseName + '/*'}
                      element={
                        <React.Suspense fallback={<div>Loading....</div>}>
                          <MentionsLegalesRoutes />
                        </React.Suspense>
                      }
                    />

                    <Route
                      path="*"
                      element={
                        <Navigate replace to={UserService.getDefaultRoute()} />
                      }
                    />
                  </Routes>
                </>
              </main>
            </Col>
          </Row>
        </>
      </Container>
    );
  }
}
