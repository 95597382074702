import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonGroup, Button } from '@dataesr/react-dsfr';
import { enCoursDeTraitement } from '../../../configs/routes';
import ConsulterChangementDeZoneEnAttenteTable from './ConsulterChangementDeZoneEnAttenteTable';
import axios from 'axios';
import TechnicalError from '../../../components/shared/technicalError/TechnicalError';

export default function PagesConsulterChangementDeZoneEnAttente() {
  let navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    document.title =
      'Liste des propositions de changement de zone de commissionnement | Commissionnement';
    axios
      .get('/commissionnements/actions/propositions-changement-zone')
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => setApiError(err))
      .finally(() => setIsLoading(false));
  }, []);

  const setData = (agents) => {
    setAgents(agents);
  };

  return (
    <>
      {!isLoading ? <TechnicalError error={apiError} /> : <></>}
      <section className="fr-app-section">
        <ConsulterChangementDeZoneEnAttenteTable
          agents={agents}
          isLoading={isLoading}
        ></ConsulterChangementDeZoneEnAttenteTable>

        <div style={{ marginTop: '1.25rem' }}>
          <ButtonGroup isEquisized align="center" isInlineFrom="md">
            <Button
              onClick={() => navigate(enCoursDeTraitement.base)}
              secondary
            >
              Retour à la liste
            </Button>
          </ButtonGroup>
        </div>
      </section>
    </>
  );
}
