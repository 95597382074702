import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  statistiquesRoutes,
  statistiquesRoutesRelative,
} from '../../configs/routes';
import UserService from '../../services/user.service';
import PagesStatsAgentsCommissionnesListe from '../../pages/statistiques/PagesStatsAgentsCommissionnesListe';
import PagesStatsAgentsCommissionnesAnneeListe from '../../pages/statistiques/PagesStatsAgentsCommissionnesAnneeListe';
import PagesStatsAgentsCommissionnesStatutListe from '../../pages/statistiques/PagesStatsAgentsCommissionnesStatutListe';
import StatsAccessService from '../../services/statsaccess.service';
import PagesStatsAgentsDecommissionnesAnneeListe from '../../pages/statistiques/PagesStatsAgentsDecommissionnesAnneeListe';
import PagesStatsAgentsZoneChangeeAnneeListe from '../../pages/statistiques/PagesStatsAgentsZoneChangeeAnneeListe';
// eslint-disable-next-line max-len
import PagesStatsAgentsChangementCommissionnementAnneeListe from '../../pages/statistiques/PagesStatsAgentsChangementCommissionnementAnneeListe';
import PagesStatsAgentsCommissionnesHabilitationListe from '../../pages/statistiques/PagesStatsAgentsCommissionnesHabilitationListe';
import PagesStatsAgentsSuiviFormationsAnneeListe from '../../pages/statistiques/PagesStatsAgentsSuiviFormationsAnneeListe';
import PagesStatsAgentsTBSuiviPDPListe from '../../pages/statistiques/PagesStatsAgentsTBSuiviPDPListe';
import PagesStatsAgentsSuiviRepartitionPDPListe from '../../pages/statistiques/PagesStatsAgentsSuiviRepartitionPDPListe';
import PagesStatsAgentsNonInscritsAnneeListe from '../../pages/statistiques/PagesStatsAgentsNonInscritsAnneeListe';

export default function StatistiquesRoutes() {
  return (
    <div className="content">
      <Routes>
        {UserService.isAdministrateur() ? (
          <>
            {/* Agents commissionnés */}
            {StatsAccessService.canAccessAgentsCommissionnes() ? (
              <>
                <Route
                  path={statistiquesRoutesRelative.agentsCommissionnesList}
                  element={<PagesStatsAgentsCommissionnesListe />}
                />
                <Route
                  path={statistiquesRoutesRelative.agentsCommissionnes}
                  element={
                    <Navigate
                      replace
                      to={statistiquesRoutes.agentsCommissionnesList}
                    />
                  }
                />
              </>
            ) : (
              <></>
            )}

            {/* Agents commissionnés par année */}
            {StatsAccessService.canAccessAgentsCommissionnesAnnee() ? (
              <>
                <Route
                  path={statistiquesRoutesRelative.agentsCommissionnesAnneeList}
                  element={<PagesStatsAgentsCommissionnesAnneeListe />}
                />
                <Route
                  path={statistiquesRoutesRelative.agentsCommissionnesAnnee}
                  element={
                    <Navigate
                      replace
                      to={statistiquesRoutes.agentsCommissionnesAnneeList}
                    />
                  }
                />
              </>
            ) : (
              <></>
            )}

            {/* Agents commissionnés par statut */}
            {StatsAccessService.canAccessAgentsCommissionnesStatut() ? (
              <>
                <Route
                  path={
                    statistiquesRoutesRelative.agentsCommissionnesStatutList
                  }
                  element={<PagesStatsAgentsCommissionnesStatutListe />}
                />
                <Route
                  path={statistiquesRoutesRelative.agentsCommissionnesStatut}
                  element={
                    <Navigate
                      replace
                      to={statistiquesRoutes.agentsCommissionnesStatutList}
                    />
                  }
                />
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        {/* Agents décommissionnés par année */}
        {StatsAccessService.canAccessAgentsDecommissionnesAnnee() ? (
          <>
            <Route
              path={statistiquesRoutesRelative.agentsDecommissionnesAnneeList}
              element={<PagesStatsAgentsDecommissionnesAnneeListe />}
            />
            <Route
              path={statistiquesRoutesRelative.agentsDecommissionnesAnnee}
              element={
                <Navigate
                  replace
                  to={statistiquesRoutes.agentsDecommissionnesAnneeList}
                />
              }
            />
          </>
        ) : (
          <></>
        )}

        {/* Agents zone changée par année */}
        {StatsAccessService.canAccessAgentsZoneChangeeAnnee() ? (
          <>
            <Route
              path={statistiquesRoutesRelative.agentsZoneChangeeAnneeList}
              element={<PagesStatsAgentsZoneChangeeAnneeListe />}
            />
            <Route
              path={statistiquesRoutesRelative.agentsZoneChangeeAnnee}
              element={
                <Navigate
                  replace
                  to={statistiquesRoutes.agentsZoneChangeeAnneeList}
                />
              }
            />
          </>
        ) : (
          <></>
        )}

        {/* Agents changement commissionnement par année */}
        {StatsAccessService.canAccessAgentsChangementCommissionnementAnnee() ? (
          <>
            <Route
              path={
                statistiquesRoutesRelative.agentsChangementCommissionnementAnneeList
              }
              element={<PagesStatsAgentsChangementCommissionnementAnneeListe />}
            />
            <Route
              path={
                statistiquesRoutesRelative.agentsChangementCommissionnementAnnee
              }
              element={
                <Navigate
                  replace
                  to={
                    statistiquesRoutes.agentsChangementCommissionnementAnneeList
                  }
                />
              }
            />
          </>
        ) : (
          <></>
        )}

        {/* Agents commissionnés habilitation */}
        {StatsAccessService.canAccessAgentsCommissionnesHabilitation() ? (
          <>
            <Route
              path={
                statistiquesRoutesRelative.agentsCommissionnesHabilitationList
              }
              element={<PagesStatsAgentsCommissionnesHabilitationListe />}
            />
            <Route
              path={statistiquesRoutesRelative.agentsCommissionnesHabilitation}
              element={
                <Navigate
                  replace
                  to={statistiquesRoutes.agentsCommissionnesHabilitationList}
                />
              }
            />
          </>
        ) : (
          <></>
        )}

        {/* Agents suivi formations année */}
        {StatsAccessService.canAccessAgentsSuiviFormationsAnnee() ? (
          <>
            <Route
              path={statistiquesRoutesRelative.agentsSuiviFormationsAnneeList}
              element={<PagesStatsAgentsSuiviFormationsAnneeListe />}
            />
            <Route
              path={statistiquesRoutesRelative.agentsSuiviFormationsAnnee}
              element={
                <Navigate
                  replace
                  to={statistiquesRoutes.agentsSuiviFormationsAnneeList}
                />
              }
            />
          </>
        ) : (
          <></>
        )}

        {/* Agents TB suivi prise de poste */}
        {StatsAccessService.canAccessAgentsTBSuiviPDP() ? (
          <>
            <Route
              path={statistiquesRoutesRelative.agentsTBSuiviPDPList}
              element={<PagesStatsAgentsTBSuiviPDPListe />}
            />
            <Route
              path={statistiquesRoutesRelative.agentsTBSuiviPDP}
              element={
                <Navigate
                  replace
                  to={statistiquesRoutes.agentsTBSuiviPDPList}
                />
              }
            />
          </>
        ) : (
          <></>
        )}

        {/* Agents suivi répartition de prise de poste */}
        {StatsAccessService.canAccessAgentsSuiviRepartitionPDP() ? (
          <>
            <Route
              path={statistiquesRoutesRelative.agentsSuiviRepartitionPDPList}
              element={<PagesStatsAgentsSuiviRepartitionPDPListe />}
            />
            <Route
              path={statistiquesRoutesRelative.agentsSuiviRepartitionPDP}
              element={
                <Navigate
                  replace
                  to={statistiquesRoutes.agentsSuiviRepartitionPDPList}
                />
              }
            />
          </>
        ) : (
          <></>
        )}

        {/* Agents non inscrits */}
        {StatsAccessService.canAccessAgentsNonInscritsAnnee() ? (
          <>
            <Route
              path={statistiquesRoutesRelative.agentsNonInscritsAnneeList}
              element={<PagesStatsAgentsNonInscritsAnneeListe />}
            />
            <Route
              path={statistiquesRoutesRelative.agentsNonInscritsAnnee}
              element={
                <Navigate
                  replace
                  to={statistiquesRoutes.agentsNonInscritsAnneeList}
                />
              }
            />
          </>
        ) : (
          <></>
        )}
      </Routes>
    </div>
  );
}
