import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Badge } from '@dataesr/react-dsfr';

export default function TechnicalError({ error }) {
  const [displayError, setDisplayError] = useState(false);
  const [message, setDisplayMessage] = useState(null);

  useEffect(() => {
    if ([404, 500].includes(error?.response?.status)) {
      setDisplayError(true);
      setDisplayMessage('Un problème technique a été rencontré');
      console.log(error);
    } else {
      setDisplayError(false);
    }
  }, []);

  return displayError ? (
    <Badge
      text={message}
      type="error"
      icon={false}
      className={'loader-center fr-app-technical-error'}
    />
  ) : (
    <></>
  );
}

TechnicalError.propTypes = {
  error: PropTypes.object,
};
