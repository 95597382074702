import React from 'react';
import LoaderCommissionnement from '../../components/shared/loaders/LoaderCommissionnement';
import TableWithLoader from '../../components/shared/tables/TableWithLoader';
import TableNbResultat from '../../components/shared/tables/TableNbResultat';
import PropTypes from 'prop-types';
import DateHelper from '../../helpers/DateHelper';
export default function SuiviActiviteTable({
  actions,
  totalActions,
  isLoading,
  hasSearchedAtLeastOnce,
}) {
  const columns = [
    {
      name: 'timestamp',
      label: 'Timestamp',
      render: ({ timestamp }) => DateHelper.formattime(timestamp),
    },
    {
      name: 'commentaire',
      label: 'Action',
    },
    {
      name: 'user_name',
      label: 'Utilisateur',
    },
  ];

  return (
    <>
      <div className="fr-app-table">
        <caption style={{ display: 'block' }}>Suivi d'activité</caption>
      </div>
      {isLoading ? (
        <LoaderCommissionnement className={'loader-center'} />
      ) : (
        <>
          <TableWithLoader
            data={actions}
            columns={columns}
            isLoading={isLoading}
            rowKey="id"
            customClassName="actions-align-center suivi-activite-tab no-caption"
          />
          <TableNbResultat
            isLoading={isLoading}
            nbResultat={totalActions}
            nbResultatAffiche={actions?.length}
            hasSearchedAtLeastOnce={hasSearchedAtLeastOnce}
          />
        </>
      )}
    </>
  );
}

SuiviActiviteTable.propTypes = {
  actions: PropTypes.array,
  totalActions: PropTypes.number,
  isLoading: PropTypes.bool,
  hasSearchedAtLeastOnce: PropTypes.bool,
};
