import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

import AuthService from './services/auth.service';
import Layout from './Layout';

// TODO: fix issue when getting url from .env file
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const onRequest = (config) => {
  const token = AuthService.getToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      AuthService.clearData();

      // TODO: better way to reload ?
      window.location.reload();
      return;
    }
  }

  return Promise.reject(error);
};

axios.interceptors.request.use(onRequest, onRequestError);
axios.interceptors.response.use(onResponse, onResponseError);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
