import axios from 'axios';
import DernieresFichesConsulteesService from './dernieresfichesconsultees.service';
import LocalStorageService from '../services/localstorage.service';

class AuthService {
  loginWithCerbere() {
    return axios.get('/oauth2/provider').then((response) => {
      const data = response.data.data;
      localStorage.setItem('oauth2.state', data.state);
      DernieresFichesConsulteesService.reset();
      LocalStorageService.cleanSearch();
      window.location.href = data.url;
    });
  }

  async logout() {
    // TODO : implements Laravel call
    try {
      const response = await axios.get('/oauth2/logout');
      this.clearData();
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  setId(id) {
    localStorage.setItem('id', id);
  }

  getId() {
    return localStorage.getItem('id');
  }

  async setToken(token) {
    return await new Promise((resolve) => {
      localStorage.setItem('_token', token);
      resolve(true);
    });
  }

  getToken() {
    return localStorage.getItem('_token');
  }

  clearData() {
    localStorage.removeItem('_token');
    localStorage.removeItem('user');
    localStorage.removeItem('id');
    localStorage.removeItem('oauth2.state');
    DernieresFichesConsulteesService.clear();
    LocalStorageService.cleanSearch();
  }
}

export default new AuthService();
