import React from 'react';

import PropTypes from 'prop-types';

import ReactLoading from 'react-loading';

import App from './App';
import UserService from './services/user.service';

export class UserLoggedIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }
  componentDidMount() {
    UserService.fetchUser().then((_) => this.setState({ isLoading: false }));
  }

  render() {
    return this.state.isLoading ? (
      <div className="fr-app-loading-container">
        {/* TODO: styling for loader */}
        <ReactLoading
          type="spin"
          color={this.props.loaderColor}
          height={100}
          width={100}
        />
        <p>Chargement en cours</p>
      </div>
    ) : (
      <App />
    );
  }
}

UserLoggedIn.propTypes = {
  loaderColor: PropTypes.string.isRequired,
};

export default UserLoggedIn;
