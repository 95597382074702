import React, { useEffect, useState } from 'react';
import { ButtonGroup, Col, Container, Row, Text } from '@dataesr/react-dsfr';
import axios from 'axios';
import LoaderCommissionnement from '../../components/shared/loaders/LoaderCommissionnement';
import TechnicalError from '../../components/shared/technicalError/TechnicalError';
import TableWithLoader from '../../components/shared/tables/TableWithLoader';
import { saveAs } from 'file-saver';
import UserService, { EAU_ET_NATURE } from '../../services/user.service';
import {
  aTraiterSuperieurDeuxMoisRouteAPI,
  configurationRoutesAPI,
} from '../../configs/routesAPI';
import DateHelper from '../../helpers/DateHelper';
import { Link } from 'react-router-dom';

export default function PagesATraiterSuperieurADeuxMois() {
  const eauNature = EAU_ET_NATURE === UserService.applicationContext();
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [apiError, setApiError] = useState(null);

  const [regions, setRegions] = useState([]);
  const [actions, setActions] = useState([]);
  const [typesCommissionnement, setTypesCommissionnement] = useState([]);
  const [currentRegionId, setCurrentRegionId] = useState(null);
  const [currentActionId, setCurrentActionId] = useState(null);
  const [currentTypeCommissionnementId, setCurrentTypeCommissionnementId] =
    useState(null);

  const [demandesSuperieureA2Mois, setDemandesSuperieureA2Mois] = useState([]);
  const [totalDemandesSuperieureA2Mois, setTotalDemandesSuperieureA2Mois] =
    useState(0);
  const [derniereMiseAJour, setDerniereMiseAJour] = useState(null);

  const columns = [
    {
      name: 'region',
      label: 'Région',
    },
    {
      name: 'service',
      label: 'Service',
    },
    {
      name: 'action_a_mener',
      label: 'Action à mener',
    },
    {
      name: 'agent',
      label: 'Agent',
    },
  ];

  useEffect(() => {
    document.title =
      'Demandes en attente depuis plus de 2 mois en région | Commissionnement';
    const urls = [
      axios.get(configurationRoutesAPI.getAllRegions),
      axios.get(configurationRoutesAPI.getTypesActionATraiterSuperieurDeuxMois),
      axios.get('/type-commissionnement?active=true'),
    ];
    axios
      .all(urls)
      .then(([regions, actions, typesCommissionnement]) => {
        setRegions(regions.data.data);
        setActions(actions.data.data);
        setTypesCommissionnement(typesCommissionnement.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setApiError(error);
        setIsLoading(false);
      });

    getATraiterData();
  }, []);

  const getATraiterData = (newData = {}) => {
    setIsTableLoading(true);
    const params = {
      type_commissionnement_id:
        newData?.typeCommissionnementId ?? currentTypeCommissionnementId,
      region_id: newData?.regionId ?? currentRegionId,
      type_action_id: newData?.typeActionId ?? currentActionId,
    };
    axios
      .get(aTraiterSuperieurDeuxMoisRouteAPI, {
        params: params,
      })
      .then((response) => {
        setIsTableLoading(false);
        setDemandesSuperieureA2Mois(response.data.data);
        setTotalDemandesSuperieureA2Mois(response.data.data.length);
        setDerniereMiseAJour(response.data.executed_at);
      })
      .catch((err) => console.log(err));
  };

  const onTypeCommissionnementChanged = (event) => {
    setCurrentTypeCommissionnementId(event.target.value);
    getATraiterData({ typeCommissionnementId: event.target.value });
  };

  const onRegionChanged = (event) => {
    setCurrentRegionId(event.target.value);
    getATraiterData({ regionId: event.target.value });
  };

  const onActionChanged = (event) => {
    setCurrentActionId(event.target.value);
    getATraiterData({ typeActionId: event.target.value });
  };

  const downloadCsv = (event) => {
    event.preventDefault();

    const params = {
      type_commissionnement_id: currentTypeCommissionnementId,
      region_id: currentRegionId,
      type_action_id: currentActionId,
    };

    axios({
      url: '/export-actions-a-traiter-2-mois',
      method: 'GET',
      responseType: 'blob',
      params: params,
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'text/csv;',
      });
      saveAs(blob, 'Demandes_en_attente_2mois.csv');
    });
  };

  const convertDate = (date) => {
    return date ? date.replace(' ', ' à ').replace(':', 'h') : '';
  };

  return isLoading ? (
    <LoaderCommissionnement />
  ) : (
    <div>
      {!isLoading ? <TechnicalError error={apiError} /> : <></>}
      <section className="fr-app-section fr-app-bloc-filtre">
        <Container fluid>
          <Row>
            {UserService.isAdministrateur() && eauNature ? (
              <>
                <Col n="6">
                  <label
                    className="fr-label"
                    htmlFor="typeCommissionnementId"
                    aria-describedby=""
                  >
                    Métier :
                  </label>
                  <select
                    className="fr-select"
                    name="typeCommissionnementId"
                    id="typeCommissionnementId"
                    value={currentTypeCommissionnementId}
                    onChange={(event) => onTypeCommissionnementChanged(event)}
                  >
                    {typesCommissionnement.length > 1 ? (
                      <option key="tous" value="">
                        Tous
                      </option>
                    ) : (
                      <></>
                    )}
                    {typesCommissionnement.map((typeCommissionnement) => {
                      return (
                        <option
                          key={typeCommissionnement.id}
                          value={typeCommissionnement.id}
                        >
                          {typeCommissionnement.label}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              </>
            ) : (
              <></>
            )}

            <Col n="6">
              <label
                className="fr-label"
                htmlFor="regionId"
                aria-describedby=""
              >
                Région :
              </label>
              <select
                className="fr-select"
                name="regionId"
                id="regionId"
                value={currentRegionId}
                onChange={(event) => onRegionChanged(event)}
              >
                <option key="toutes" value="">
                  Toutes
                </option>
                {regions.map((region) => {
                  return (
                    <option key={region.id} value={region.id}>
                      {region.label}
                    </option>
                  );
                })}
              </select>
            </Col>

            <Col n="8">
              <label
                className="fr-label"
                htmlFor="actionId"
                aria-describedby=""
              >
                Action :
              </label>
              <select
                className="fr-select"
                name="actionId"
                id="actionId"
                value={currentActionId}
                onChange={(event) => onActionChanged(event)}
              >
                <option key="toutes" value="">
                  Toutes
                </option>
                {actions.map((action) => {
                  return (
                    <option key={action.id} value={action.id}>
                      {action.label}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="fr-app-section">
        <div className="fr-app-table">
          <caption style={{ display: 'block' }}>
            Demandes en attente depuis plus de 2 mois en région
          </caption>
        </div>

        {!isTableLoading ? (
          <div style={{ padding: '0px 10px' }}>
            <Text size="xs" className="remark roboto-italic">
              {derniereMiseAJour ? (
                <span>
                  Dernière mise à jour le :{' '}
                  {convertDate(DateHelper.formattime(derniereMiseAJour))}
                </span>
              ) : (
                ''
              )}
            </Text>
          </div>
        ) : (
          <></>
        )}
        <TableWithLoader
          data={demandesSuperieureA2Mois}
          columns={columns}
          isLoading={isTableLoading}
          rowKey="agent_id"
          customClassName="a-traiter-sup-deux-mois no-caption"
        ></TableWithLoader>

        {!isTableLoading ? (
          <div style={{ padding: '0px 10px' }}>
            <Text size="xs" className="roboto-italic fr-app-descriptif">
              {totalDemandesSuperieureA2Mois}{' '}
              {totalDemandesSuperieureA2Mois > 1 ? 'résultats' : 'résultat'}
            </Text>
          </div>
        ) : (
          <></>
        )}

        <ButtonGroup isEquisized align="center" isInlineFrom="md">
          <Link
            type="button"
            className="fr-btn--md fr-btn btn-action-trigger"
            title="Export CSV"
            onClick={downloadCsv}
            to={''}
            disabled={isTableLoading}
            download
          >
            Export CSV
          </Link>
        </ButtonGroup>
      </section>
    </div>
  );
}
