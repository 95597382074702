import React from 'react';
import PropTypes from 'prop-types';

export default function SelectAnnee({
  register,
  handleChange,
  anneeMin,
  anneeMax,
}) {
  let listAnnees = [];
  for (let i = anneeMin; i <= anneeMax; i++) {
    listAnnees.unshift(i);
  }

  return (
    <>
      <label className="fr-label" htmlFor="annee">
        Année :
      </label>
      <select
        className="fr-select"
        name="annee"
        id="annee"
        {...register('annee')}
        onChange={handleChange}
      >
        {listAnnees.map((annee) => {
          return (
            <option key={annee} value={annee}>
              {annee}
            </option>
          );
        })}
      </select>
    </>
  );
}

SelectAnnee.propTypes = {
  register: PropTypes.any,
  handleChange: PropTypes.func,
  anneeMin: PropTypes.number,
  anneeMax: PropTypes.number,
};
