import React from 'react';

import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

import colors from '../../../styles/color.scss';
import UserService, { EAU_ET_NATURE } from '../../../services/user.service';

export default function LoaderCommissionnement({ height, width, className }) {
  const loaderColor =
    EAU_ET_NATURE === UserService.applicationContext()
      ? colors.apporange
      : colors.appgreen;

  return (
    <div className={className ?? 'loader-full-page-center'}>
      <ReactLoading
        type="spin"
        color={loaderColor}
        height={height ?? 100}
        width={width ?? 100}
      />
    </div>
  );
}

LoaderCommissionnement.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};
