import React from 'react';

import 'react-toastify/dist/ReactToastify.css';

// Remix Icon
import 'remixicon/fonts/remixicon.css';

// App Styles
import './styles/style.scss';

// App Layout & Component
import AppHeader from './layouts/AppHeader';
import AppMain from './layouts/AppMain';
import AppFooter from './layouts/AppFooter';
import AppToggleModalBtns from './layouts/AppToggleModalBtns';
import UserService from './services/user.service';

export default function App() {
  return (
    <div
      className={
        'EAU_ET_NATURE' === UserService.applicationContext()
          ? 'fr-app fr-app-eaunature'
          : 'fr-app fr-app-icpe'
      }
    >
      <div className="fr-skiplinks">
        <nav
          role="navigation"
          aria-label="Accès rapide"
          className="fr-container"
        >
          <ul className="fr-skiplinks__list">
            <li>
              <a id="skiplink__contenu" href="#contenu" className="fr-link">
                Contenu
              </a>
            </li>
            <li>
              <a id="skiplink__menu" href="#main-nav" className="fr-link">
                Menu
              </a>
            </li>
            <li>
              <a id="skiplink__pied-de-page" href="#footer" className="fr-link">
                Pied de page
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <AppHeader />
      <AppMain />
      <AppToggleModalBtns />
      <AppFooter />
    </div>
  );
}
