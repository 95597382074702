class DernieresFichesConsulteesService {
  get = () => {
    return localStorage.getItem('dernieresFichesConsultees')
      ? JSON.parse(localStorage.getItem('dernieresFichesConsultees'))
      : [];
  };

  put = (agent) => {
    if (!agent.id) {
      return;
    }

    const dernieresFiches = this.get().filter((fiche) => fiche.id !== agent.id);

    dernieresFiches.unshift({
      id: agent.id,
      nom: agent.nom_usage ?? agent.nom,
      prenom: agent.prenom,
    });

    if (dernieresFiches.length > 3) {
      dernieresFiches.length = 3;
    }

    localStorage.setItem(
      'dernieresFichesConsultees',
      JSON.stringify(dernieresFiches)
    );
  };

  reset = () => {
    localStorage.setItem('dernieresFichesConsultees', JSON.stringify([]));
  };

  clear = () => {
    localStorage.removeItem('dernieresFichesConsultees');
  };
}

export default new DernieresFichesConsulteesService();
