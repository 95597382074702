export const localStorageSearchKey = {
  searchAgentsListe: '_listeAgentsSearch',
  searchAgentsHabilitesListe: '_listeAgentshabilitesSearch',
  searchAgentsCommissionnesListe: '_listeAgentscommissionnesSearch',
  searchAgentsFicheDePosteListe: '_listeAgentsFichesDePosteSearch',
  searchArchivesAgentsRetraitesListe: '_listeArchivesAgentsRetraitesSearch',
  searchArchivesAgentsArchivesListe: '_listeArchivesAgentsArchivesSearch',
  searchFormationsSessionsListe: '_listeFormationsSessionsSearch',
  searchArriveesAgentsListe: '_listeArriveesAgentsSearch',
  searchSuiviActiviteListe: '_listeSuiviActiviteSearch',
  searchStatistiquesListe: '_listeStatistiquesSearch',
  searchStatistiquesPeriodListe: '_listeStatistiquesPeriodSearch',
};
