export const allCursusEnum = {
  FORMA_APPROFONDIE: 1,
  CONSO_PRISE_DE_POSTE: 2,
  PREPA_PRISE_DE_POSTE: 3,
  PRISE_DE_POSTE: 4,
  FORMATION_AGRICOLE: 5,
  FAUNE_SAUVAGE_CAPTIVE: 6,
  NATURE: 7,
  MER: 8,
  EAU_NATURE_PARCS_NATIONAUX: 9,
  NATURE_SITES_PUBLICITE: 10,
  EAU_NATURE_MER_SITES_AAMP: 11,
  PUBLICITES: 12,
  GARDE_LITTORAL: 13,
  RESERVES_ESPACES_MARITIMES: 14,
  RESERVES_POLICE_CHASSE: 15,
  RESERVES_POLICE_PECHE: 16,
  RESERVES_POLICE_EAU: 17,
  RESERVES: 18,
  EAU_NATURE_SITES_AFB_ONCFS: 19,
  MILIEUX_MARINS_AFB: 20,
  SITES: 21,
  PATRIMOINE_NATUREL_ENV_EN_MER: 22,
  EAU_NATURE_SITES_SERVICES_ETAT: 23,
  EAU: 24,
  AGENTS_COLLECTIVITES: 25,
  EAU_FAUNE_SAUVAGE_CAPTIVE: 26,
  EAU_PUBLICITE: 27,
  EAU_DOUCE_MER: 28,
  RESERVES_FAUNE_FLORE_VTM: 29,
  SECURITE_OUVRAGES_HYDRAULIQUES: 30,
};
