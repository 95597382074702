import React, { useState, useEffect } from 'react';

// Système de Design de l'État
import {
  Button,
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
  ModalClose,
  Text,
  Checkbox,
  Link,
  Badge,
} from '@dataesr/react-dsfr';
import axios from 'axios';
import UserService from '../services/user.service';
import {
  configurationRoutesAPI,
  messageADiffuserRouteApi,
} from '../configs/routesAPI';
import TechnicalError from '../components/shared/technicalError/TechnicalError';
import LoaderCommissionnement from '../components/shared/loaders/LoaderCommissionnement';

export default function AppToggleModalBtns() {
  const DOMPurify = require('dompurify')(window);
  const [isDiffuserMsgModalOpen, toggleDiffuserMsgModal] = useState(false);
  const [isLoadingDiffuserMsg, setIsLoadingDiffuserMsg] = useState(true);
  const [messageADiffuser, setMessageADiffuser] = useState('');
  const [
    displayMessageADiffuserModalButton,
    setDisplayMessageADiffuserModalButton,
  ] = useState(false);
  const [hasAlreadyReadMessage, setHasAlreadyReadMessage] = useState(false);
  const [apiErrorMessageADiffuser, setApiErrorMessageADiffuser] =
    useState(null);
  const [dontDisplayMessageAgain, setDontDisplayMessageAgain] = useState(false);

  const [isAssistanceModalOpen, toggleAssistanceModal] = useState(false);
  const [isLoadingAssistance, setIsLoadingAssistance] = useState(true);
  const [assistanceDescription, setAssistanceDescription] = useState('');
  const [apiErrorAssistance, setApiErrorAssistance] = useState(null);

  useEffect(() => {
    axios
      .get(configurationRoutesAPI.getAssistanceDescription)
      .then((res) => {
        setAssistanceDescription(res.data.data.value);
      })
      .catch((error) => {
        setApiErrorAssistance(error);
      })
      .finally(() => setIsLoadingAssistance(false));

    axios
      .get(messageADiffuserRouteApi)
      .then((res) => {
        const data = res.data.data;
        setMessageADiffuser(data.message);
        setHasAlreadyReadMessage(data.hasAlreadyReadMessage);
        setDisplayMessageADiffuserModalButton(data.displayModalButton);
      })
      .catch((error) => {
        setApiErrorMessageADiffuser(error);
      })
      .finally(() => setIsLoadingDiffuserMsg(false));
  }, []);

  const closeMessageADiffuserModal = () => {
    toggleDiffuserMsgModal(false);
    if (dontDisplayMessageAgain) {
      setHasAlreadyReadMessage(true);
      axios
        .post(messageADiffuserRouteApi)
        .then((_) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="fr-app-toggle-modal-btn-container">
      {displayMessageADiffuserModalButton ? (
        <>
          <Button
            title="Message à diffuser"
            className="fr-app-modal-toggle-btn msg-diffuser-modal-toggle fr-app-btn-icon"
            onClick={() => toggleDiffuserMsgModal(true)}
          >
            <span className="ri-message-3-line" />
            {!hasAlreadyReadMessage ? (
              <Badge
                className="badge-has-read-message"
                text="1"
                type="error"
                icon={false}
              />
            ) : (
              <></>
            )}
          </Button>
        </>
      ) : (
        <></>
      )}
      <Modal
        size="lg"
        className="fr-app-modal msg-diffuser-modal"
        isOpen={isDiffuserMsgModalOpen}
        hide={() => toggleDiffuserMsgModal(false)}
      >
        <ModalClose hide={() => toggleDiffuserMsgModal(false)} title="Fermer">
          Fermer
        </ModalClose>
        <ModalTitle icon="ri-message-3-line">Message à diffuser</ModalTitle>
        <ModalContent>
          {!isLoadingDiffuserMsg ? (
            <TechnicalError error={apiErrorMessageADiffuser} />
          ) : (
            <></>
          )}
          {isLoadingDiffuserMsg ? (
            <LoaderCommissionnement className={'loader-center'} />
          ) : (
            <Text size="md">
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(messageADiffuser),
                }}
              ></div>
            </Text>
          )}
        </ModalContent>
        <ModalFooter>
          {!UserService.hasSwitchedProfil() ? (
            <Checkbox
              value="value-1"
              onChange={() => {
                setDontDisplayMessageAgain(true);
              }}
              label="Ne plus afficher ce message"
            />
          ) : (
            <></>
          )}
          <div className="close-btn-container">
            <Button title="Fermer" onClick={() => closeMessageADiffuserModal()}>
              Fermer
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      <Button
        title="Assistance"
        className="fr-app-modal-toggle-btn assistance-modal-toggle"
        onClick={() => toggleAssistanceModal(true)}
      >
        Assistance
      </Button>
      <Modal
        size="md"
        className={
          'EAU_ET_NATURE' === UserService.applicationContext()
            ? 'fr-app-modal assistance-modal fr-app-eaunature'
            : 'fr-app-modal assistance-modal fr-app-icpe'
        }
        isOpen={isAssistanceModalOpen}
        hide={() => toggleAssistanceModal(false)}
      >
        <ModalClose hide={() => toggleAssistanceModal(false)} title="Fermer">
          Fermer
        </ModalClose>
        <ModalTitle>Assistance</ModalTitle>
        <ModalContent>
          <>
            {!isLoadingAssistance ? (
              <TechnicalError error={apiErrorAssistance} />
            ) : (
              <></>
            )}
            <div className="fr-app-linklist-container">
              <Link
                href={`mailto:${process.env.REACT_APP_ASSISTANCE_EMAIL}`}
                icon="ri-mail-line"
                iconPosition="left"
                className="fr-app-btn-icon icon-not-underline"
              >
                Envoyer un e-mail
              </Link>
            </div>
            {isLoadingAssistance ? (
              <LoaderCommissionnement className={'loader-center'} />
            ) : (
              <Text size="md">{assistanceDescription}</Text>
            )}
          </>
        </ModalContent>
      </Modal>
    </div>
  );
}
