import React, { useState } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Breadcrumb, BreadcrumbItem } from '@dataesr/react-dsfr';
import axios from 'axios';
import { configurationRoutesAPI } from '../configs/routesAPI';
import { commissionnementArretesAction } from '../enums/Enums';
import UserService from '../services/user.service';

export default function AppBreadcrumb() {
  const [labelCursus, setLabelCursus] = useState('');
  const [labelModule, setLabelModule] = useState('');

  const cursusBreadcrumb = ({ match }) => {
    axios
      .get(
        configurationRoutesAPI.getCursus.replace(
          ':cursusId',
          match.params.cursusId
        )
      )
      .then((resp) => setLabelCursus(resp.data.data.label));
    return <span>{labelCursus}</span>;
  };

  const moduleBreadcrumb = ({ match }) => {
    axios
      .get(
        configurationRoutesAPI.getModule.replace(
          ':moduleId',
          match.params.moduleId
        )
      )
      .then((resp) => setLabelModule(resp.data.data.label));
    return <span>{labelModule}</span>;
  };

  // breadcumbs can be layouts or strings
  const routes = [
    { path: '/', breadcrumb: 'Accueil' },
    {
      path: 'parametrage',
      breadcrumb: 'Paramétrage',
      children: [
        {
          path: 'Mailscc',
          breadcrumb: 'Mails Cc',
          children: [
            {
              path: 'ajouter',
              breadcrumb: 'Nouveau',
            },
            {
              path: 'modifier',
              breadcrumb: null,
              children: [
                {
                  path: ':id',
                  breadcrumb: 'Modifier',
                },
              ],
            },
          ],
        },
        {
          path: 'tablesreference',
          breadcrumb: 'Tables de référence',
          children: [
            {
              path: 'agents',
              children: [
                {
                  path: 'statuts',
                  breadcrumb: "Statut d'un agent",
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'groupesstatut',
                  breadcrumb: 'Groupes de statut',
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'dominantemetier',
                  breadcrumb: 'Dominante métier du poste actif',
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'typeadmin',
                  breadcrumb: "Type d'administration",
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'services',
                  breadcrumb: 'Service',
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'cursusalleges',
                  breadcrumb: 'Cursus allégé',
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: 'formations',
              children: [
                {
                  path: 'cursus',
                  breadcrumb: 'Cursus',
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'modules',
                  breadcrumb: 'Module',
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'organismes',
                  breadcrumb: 'Organisme de formation',
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'lieux',
                  breadcrumb: 'Lieu de formation',
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: 'commissionnements',
              children: [
                {
                  path: 'habilitations',
                  breadcrumb: 'Habilitation',
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'zonescommissionnements',
                  breadcrumb: 'Zone de commissionnement',
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'expediteursbordereau',
                  breadcrumb: 'Expéditeur de bordereau',
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'destinatairesbordereau',
                  breadcrumb: 'Destinataire de bordereau',
                  children: [
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: 'statistiques',
              children: [
                {
                  path: 'groupestypeadministrationadmindgalnhabilitation',
                  breadcrumb: "Groupes type d'administration habilitation",
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'groupestypeadministrationadmindgaln',
                  breadcrumb: "Groupes type d'administration",
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'groupestypeadministrationadmindgpr',
                  breadcrumb: "Groupes type d'administration",
                  children: [
                    {
                      path: 'ajouter',
                      breadcrumb: 'Nouveau',
                    },
                    {
                      path: 'modifier',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':id',
                          breadcrumb: 'Modifier',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'configurerappli',
          breadcrumb: "Configurer l'application",
          children: [],
        },
        {
          path: 'manuels',
          breadcrumb: 'Modifier les manuels utilisateur',
          children: [],
        },
      ],
    },
    {
      path: 'gestiondesprofils',
      breadcrumb: 'Gestion des profils',
    },
    {
      path: 'atraiter',
      breadcrumb: null,
      children: [
        {
          path: 'consulterdemandescreationagent',
          breadcrumb: "Demandes de création de dossiers d'agents",
        },
        {
          path: 'consulterarriveesprogrammees',
          breadcrumb: 'Les arrivées programmées',
        },
        {
          path: 'consulteragentshabilitables',
          breadcrumb: "Demandes de proposition d'habilitation",
        },
        {
          path: 'validerdemandecreationagent',
          breadcrumb: 'Valider demande création agent',
          children: [{ path: ':agentId', breadcrumb: null }],
        },
        {
          path: 'consulterrefuscreationagent',
          breadcrumb: 'Consulter les refus de création d’agent',
          children: [
            {
              path: 'modifiercreationagent',
              breadcrumb: 'Modifier création agent',
              children: [
                {
                  path: 'donneesagent',
                  breadcrumb: null,
                  children: [
                    {
                      path: ':agentId',
                      breadcrumb: null,
                    },
                  ],
                },
                {
                  path: 'donneesposte',
                  breadcrumb: null,
                  children: [
                    {
                      path: ':agentId',
                      breadcrumb: null,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'consulterprisesdeposte',
          breadcrumb: "Demandes d'inscription aux sessions Prise de poste",
        },
        {
          path: 'consulterequivalencesavalider',
          breadcrumb: 'Équivalences à valider',
        },
        {
          path: 'validerequivalence',
          breadcrumb: 'Équivalences à valider',
          children: [
            {
              path: ':agentId',
              breadcrumb: null,
              children: [
                {
                  path: ':equivalenceId',
                  breadcrumb: "Valider l'équivalence",
                },
              ],
            },
          ],
        },
        {
          path: 'consulterautoformationsainscrire',
          breadcrumb: 'Agents à inscrire à la FOAD',
        },
        {
          path: 'consulterautoformations',
          breadcrumb: 'Suivi FOAD',
        },
        {
          path: 'consulteragentsainscrire',
          breadcrumb: 'Agents à inscrire',
        },
        {
          path: 'consultermoduleagricoleainscrire',
          breadcrumb: 'Agents à inscrire au module agricole',
        },
        {
          path: 'consultermoduletutoratainscrire',
          breadcrumb: 'Agents en attente de renseignement du tutorat',
        },
        {
          path: 'consultersemaineconsolidation',
          breadcrumb: 'Consulter semaine consolidation',
        },
        {
          path: 'consulterTransfertsRefuses',
          breadcrumb: 'Transferts de sessions refusés',
        },
        {
          path: 'consulterdemandesinscriptions',
          breadcrumb: "Demandes d'inscriptions",
        },
        {
          path: 'consulterinscriptionsrefusees',
          breadcrumb: 'Inscriptions aux sessions refusées',
        },
        {
          path: 'consultertransfertssessiondemandes',
          breadcrumb: 'Transferts de sessions demandés',
        },
        {
          path: 'consulterdemandesdetransfert',
          breadcrumb: 'Demandes de transfert de sessions',
          children: [{ path: ':inscriptionId', breadcrumb: 'A valider' }],
        },
        {
          path: 'consultercommissionnementsaproposer',
          breadcrumb: 'Demandes de proposition de commissionnement',
          children: [
            {
              path: 'proposer',
              breadcrumb: null,
              children: [
                {
                  path: ':agentId',
                  breadcrumb: 'Proposer le commissionnement',
                },
              ],
            },
          ],
        },
        {
          path: 'consultercommissionnementschangementaproposer',
          breadcrumb:
            'Demandes de proposition de changement de commissionnement',
          children: [
            {
              path: 'proposer',
              breadcrumb: null,
              children: [
                {
                  path: ':agentId',
                  breadcrumb: 'Proposer le changement commissionnement',
                },
              ],
            },
          ],
        },
        {
          path: 'consultercommissionnementscartesaediter',
          breadcrumb: 'Cartes de commissionnement à éditer',
        },
        {
          path: 'Consulterpropositionscommissionnement',
          breadcrumb: 'Propositions de commissionnement',
          children: [
            {
              path: 'valider',
              breadcrumb: null,
              children: [
                {
                  path: ':agentId',
                  breadcrumb: null,
                  children: [
                    {
                      path: ':commissionnementId',
                      breadcrumb: 'Valider la proposition de commissionnement',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'consulterPropositionsCommissionnementChangement',
          breadcrumb: 'Propositions de changement de commissionnement',
          children: [
            {
              path: 'valider',
              breadcrumb: null,
              children: [
                {
                  path: ':agentId',
                  breadcrumb: null,
                  children: [
                    {
                      path: ':commissionnementId',
                      breadcrumb:
                        'Valider la proposition de changement de commissionnement',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'consultercommissionnementsrefuses',
          breadcrumb: 'Refus de commissionnement',
        },
        {
          path: 'consultercommissionnementschangementrefuses',
          breadcrumb: 'Refus de changement commissionnement',
        },
        {
          path: 'consulterprestationssermentarenseigner',
          breadcrumb: 'Commissionnements en attente de prestation de serment',
          children: [
            {
              path: 'renseigner',
              breadcrumb: null,
              children: [
                {
                  path: ':agentId',
                  breadcrumb: 'Renseigner la prestation de serment',
                },
              ],
            },
          ],
        },
        {
          path: 'consulterprestationssermentavalider',
          breadcrumb: 'Prestations de serment à valider',
          children: [
            {
              path: 'valider',
              breadcrumb: null,
              children: [
                {
                  path: ':agentId',
                  breadcrumb: 'Valider la prestation de serment',
                },
              ],
            },
          ],
        },
        {
          path: 'Consulterprestationsdesermentrefusees',
          breadcrumb: 'Refus de prestation de serment',
          children: [
            {
              path: 'renseigner',
              breadcrumb: null,
              children: [
                {
                  path: ':agentId',
                  breadcrumb: "Renseigner la prestation de serment d'un agent",
                },
              ],
            },
          ],
        },
        {
          path: 'consulterchangementdezonerefuses',
          breadcrumb: 'Changement de zone refusés',
        },
        {
          path: 'consulterchangementdeservicerefuses',
          breadcrumb: 'Changement de service refusés',
        },
        {
          path: 'consultereditionscartesrefusees',
          breadcrumb: 'Editions de cartes refusées',
        },
        {
          path: 'consulterdecommissionnementsrefuses',
          breadcrumb: 'Décommissionnements refusés',
        },
        {
          path: 'consulterpropositionschangementzone',
          breadcrumb: 'Propositions de changement de zone',
          children: [
            {
              path: 'valider',
              breadcrumb: null,
              children: [
                {
                  path: ':agentId',
                  breadcrumb: null,
                  children: [
                    {
                      path: ':actionId',
                      breadcrumb: 'Valider la demande',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'consulterpropositionsdecommissionnement',
          breadcrumb: 'Propositions de décommissionnement',
          children: [
            {
              path: 'valider',
              breadcrumb: null,
              children: [
                {
                  path: ':agentId',
                  breadcrumb: null,
                  children: [
                    {
                      path: ':actionId',
                      breadcrumb: 'Valider la demande',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'consultercartesarenouveler',
          breadcrumb: 'Cartes à renouveler',
          children: [
            {
              path: ':agentId',
              breadcrumb: null,
              children: [
                {
                  path: ':carteId',
                  breadcrumb: 'Proposer le renouvellement',
                },
              ],
            },
          ],
        },
        {
          path: 'consultercartesadetruire',
          breadcrumb: 'Cartes à détruire',
          children: [
            {
              path: ':agentId',
              breadcrumb: null,
              children: [
                {
                  path: ':carteId',
                  breadcrumb: 'Détruire la carte',
                },
              ],
            },
          ],
        },
        {
          path: 'consultercartesarenouvelerrapidement',
          breadcrumb: 'Cartes à renouveler rapidement',
        },
        {
          path: 'consulteragentsaumoinsunenote',
          breadcrumb: 'Agents ayant au moins une note',
        },
        {
          path: 'consulterbordereauxareceptionner',
          breadcrumb: 'Bordereaux à réceptionner',
        },
        {
          path: 'consulterbordereauxnonreceptionnes',
          breadcrumb: 'Bordereaux non réceptionnés',
        },
      ],
    },
    {
      path: 'encoursdetraitement',
      breadcrumb: 'En cours de traitement',
      children: [
        {
          path: 'agentscreationsenattente',
          breadcrumb: "Demandes de création de dossiers d'agents",
        },
        {
          path: 'inscriptionsenattente',
          breadcrumb: "Demandes d'inscriptions",
        },
        {
          path: 'demandesdetransfert',
          breadcrumb: 'Demandes de transferts',
        },
        {
          path: 'commissionnementspropositions',
          breadcrumb: 'Propositions de commissionnement',
        },
        {
          path: 'commissionnementsenattentearrete',
          breadcrumb: "Commissionnements en attente de l'arrêté ministériel",
        },
        {
          path: 'commissionnementscartesaediter',
          breadcrumb: 'Cartes de commissionnement à éditer',
        },
        {
          path: 'changementszoneenattente',
          breadcrumb: 'Propositions de changement de zone',
        },
        {
          path: 'changementscommissionnementenattente',
          breadcrumb: 'Propositions de changement de commissionnement',
        },
        {
          path: 'prestationsenattente',
          breadcrumb: 'Propositions de serment à valider',
        },
        {
          path: 'decommissionnementsenattentearrete',
          breadcrumb: 'Décommissionnements en attente de l’arrêté ministériel',
        },
      ],
    },
    {
      path: 'commissionnements',
      breadcrumb: 'Commissionnements',
      children: [
        {
          path: 'agents',
          breadcrumb: null,
          children: [
            {
              path: 'habilites',
              breadcrumb: null,
              children: [
                {
                  path: 'liste',
                  breadcrumb: 'Agents habilités',
                },
              ],
            },
            {
              path: 'commissionnes',
              breadcrumb: null,
              children: [
                {
                  path: 'liste',
                  breadcrumb: 'Agents commissionnés',
                },
              ],
            },
          ],
        },
        {
          path: 'arretesnomination',
          breadcrumb: 'Arrêtés de nomination',
          children: [
            {
              path: commissionnementArretesAction.ARRETES_A_EDITER,
              breadcrumb: 'À éditer',
            },
            {
              path: commissionnementArretesAction.ARRETES_EDITES_AVEC_NOR,
              breadcrumb: 'Édités avec n° NOR',
            },
            {
              path: commissionnementArretesAction.ARRETES_NOTIFIES,
              breadcrumb: 'Notifiés',
            },
            {
              path: ':arreteNominationId',
              breadcrumb: null,
              children: [
                {
                  path: 'notifieragents',
                  breadcrumb: 'Notification des agents',
                },
              ],
            },
          ],
        },
        {
          path: 'bordereaux',
          breadcrumb: 'Bordereaux et cartes',
        },
      ],
    },
    {
      path: 'agents',
      breadcrumb: 'Agents',
      children: [
        {
          path: 'liste',
          breadcrumb: UserService.isConsultant()
            ? 'Rechercher un agent'
            : 'Rechercher / créer un agent',
        },
        {
          path: 'fichesdeposte',
          children: [{ path: 'liste', breadcrumb: null }],
          breadcrumb: 'Fiches de poste',
        },
        {
          path: ':agentId',
          breadcrumb: null,
          children: [
            { path: 'consulteragent', breadcrumb: 'Agent - Consulter' },
            { path: 'modifieragent', breadcrumb: 'Agent - Modifier' },
            {
              path: 'consulterpostes',
              breadcrumb: 'Consulter - Postes',
            },
            {
              path: 'enregistrerdepart',
              breadcrumb: "Fin d'affectation - Postes",
            },
            {
              path: 'modifierarrivee',
              breadcrumb: "Modifier l'affectation",
              children: [{ path: ':id', breadcrumb: null }],
            },
            {
              path: 'modifierprofilposte',
              breadcrumb: "Modification d'un poste",
              children: [{ path: ':posteId', breadcrumb: null }],
            },
            {
              path: 'inscrireautoformation',
              breadcrumb: 'Inscription FOAD',
              children: [{ path: ':foadId', breadcrumb: null }],
            },
            {
              path: 'validersuiviautoformation',
              breadcrumb: 'Suivi FOAD',
              children: [{ path: ':foadId', breadcrumb: null }],
            },
            {
              path: 'consulterhabilitations',
              breadcrumb: 'Habilitations',
              children: [
                {
                  path: 'valider',
                  breadcrumb: "Valider l'habilitation d'un agent",
                },
              ],
            },
            {
              path: 'consulternotes',
              breadcrumb: 'Notes',
            },
            {
              path: 'consulterformations',
              breadcrumb: 'Formations',
              children: [
                {
                  path: 'consulterhistorique',
                  breadcrumb: 'Historique',
                },
                {
                  path: 'cursus',
                  breadcrumb: null,
                  children: [
                    {
                      path: ':cursusId',
                      breadcrumb: cursusBreadcrumb,
                      children: [
                        {
                          path: 'module',
                          breadcrumb: null,
                          children: [
                            {
                              path: ':moduleId',
                              breadcrumb: moduleBreadcrumb,
                              children: [
                                {
                                  path: ':moduleId',
                                  breadcrumb: null,
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: 'consultercommissionnements',
              breadcrumb: 'Commissionnements',
              children: [
                {
                  path: 'proposer',
                  breadcrumb: 'Proposer le commissionnement',
                },
                {
                  path: 'proposerchangementdezone',
                  breadcrumb: 'Modifier la zone de commissionnement',
                },
                {
                  path: 'proposerdecommissionnement',
                  breadcrumb: 'Proposer le décommissionnement',
                },
                {
                  path: 'declarercarteperdue',
                  breadcrumb: 'Déclarer carte perdue',
                },
              ],
            },
          ],
        },
        { path: 'ajouteragent', breadcrumb: 'Ajouter' },
        {
          path: 'departs',
          breadcrumb: 'Les départs à venir',
          children: [
            {
              path: 'liste',
              breadcrumb: null,
            },
            {
              path: 'modifier',
              breadcrumb: null,
              children: [
                {
                  path: ':agentId',
                  breadcrumb: 'Agent départ - Modifier',
                },
              ],
            },
          ],
        },
        {
          path: 'listenonaffectes',
          breadcrumb: 'Les agents non affectés > 30 jours',
          children: [
            {
              path: 'liste',
              breadcrumb: null,
            },
          ],
        },
        {
          path: 'lesarrivees',
          breadcrumb: 'Les arrivées / agents non affectés',
          children: [
            {
              path: 'liste',
              breadcrumb: null,
            },
            {
              path: 'enregistrer',
              breadcrumb: null,
              children: [
                {
                  path: ':agentId',
                  breadcrumb: 'Enregistrer une arrivée',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'formations',
      children: [
        {
          path: 'sessions',
          children: [
            { path: 'liste', breadcrumb: 'Recherche' },
            {
              path: 'modifier',
              breadcrumb: null,
              children: [{ path: ':sessionId', breadcrumb: 'Modifier' }],
            },
            {
              path: 'consulter',
              breadcrumb: null,
              children: [{ path: ':sessionId', breadcrumb: 'Consulter' }],
            },
            {
              path: 'inscriptions',
              children: [
                {
                  path: ':inscriptionId',
                  breadcrumb: null,
                  children: [
                    {
                      path: 'validerdemandeinscription',
                      breadcrumb: 'Valider demande inscription',
                    },
                    {
                      path: 'modifierrelationagent',
                      breadcrumb: null,
                      children: [
                        {
                          path: ':sessionId',
                          breadcrumb: 'Modification de la participation',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'archives',
      breadcrumb: 'Archives',
      children: [
        {
          path: 'agents/retraites',
          breadcrumb: 'Retraités',
          children: [
            { path: 'liste', breadcrumb: 'Recherche' },
            {
              path: ':agentId',
              breadcrumb: 'Consulter',
              children: [{ path: 'consulteragent', breadcrumb: null }],
            },
          ],
        },
        {
          path: 'agents/archives',
          breadcrumb: 'Archivés',
          children: [
            { path: 'liste', breadcrumb: 'Recherche' },
            {
              path: ':agentId',
              breadcrumb: 'Consulter',
              children: [{ path: 'consulteragent', breadcrumb: null }],
            },
          ],
        },
      ],
    },
    {
      path: 'suiviactivite',
      breadcrumb: "Suivi d'activité",
    },
    {
      path: 'atraitersuperieurdeuxmois',
      breadcrumb: 'A traiter Régions > 2 mois',
    },
    {
      path: 'statistiques',
      breadcrumb: 'Statistiques',
      children: [
        {
          path: 'agentscommissionnes',
          breadcrumb: 'Agents commissionnés',
          children: [{ path: 'liste', breadcrumb: 'Liste' }],
        },
        {
          path: 'agentscommissionnesannee',
          breadcrumb: 'Agents commissionnés par année',
          children: [{ path: 'liste', breadcrumb: 'Liste' }],
        },
        {
          path: 'agentscommissionnesstatut',
          breadcrumb: 'Agents commissionnés par statut',
          children: [{ path: 'liste', breadcrumb: 'Liste' }],
        },
        {
          path: 'agentsdecommissionnesannee',
          breadcrumb: 'Agents décommissionnés par année',
          children: [{ path: 'liste', breadcrumb: 'Liste' }],
        },
        {
          path: 'agentszonechangeeannee',
          breadcrumb: 'Agents ayant changé de zone par année',
          children: [{ path: 'liste', breadcrumb: 'Liste' }],
        },
        {
          path: 'agentschangementcommissionnementannee',
          breadcrumb: 'Agents changement de commissionnement par année',
          children: [{ path: 'liste', breadcrumb: 'Liste' }],
        },
        {
          path: 'agentscommissionneshabilitation',
          breadcrumb: "Agents commissionnés par type d'habilitation",
          children: [{ path: 'liste', breadcrumb: 'Liste' }],
        },
        {
          path: 'agentssuiviformationsannee',
          breadcrumb: 'Suivi des formations des agents par année',
          children: [{ path: 'liste', breadcrumb: 'Liste' }],
        },
        {
          path: 'agentstbsuivipdp',
          breadcrumb: 'TB Suivi de prise de poste',
          children: [{ path: 'liste', breadcrumb: 'Liste' }],
        },
        {
          path: 'agentssuivirepartitionpdp',
          breadcrumb: 'Suivi répartition de prise de poste',
          children: [{ path: 'liste', breadcrumb: 'Liste' }],
        },
        {
          path: 'agentsnoninscritsannee',
          breadcrumb: 'Agents non inscrits SM / SA / ST / SC',
          children: [{ path: 'liste', breadcrumb: 'Liste' }],
        },
      ],
    },
    {
      path: 'mentions_legales',
      breadcrumb: 'Mentions légales',
    },
    {
      path: 'accessibilite',
      breadcrumb: 'Accessibilité : partiellement conforme',
    },
    {
      path: 'sitemap',
      breadcrumb: 'Plan du site',
    },
  ];

  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <React.Fragment>
      <Breadcrumb className="fr-app-breadcrumb">
        {breadcrumbs.map(({ match, breadcrumb }, index) =>
          index + 1 === breadcrumbs.length ? (
            <BreadcrumbItem key={match.pathname} aria-current="page">
              {breadcrumb}
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem key={match.pathname} href={match.pathname}>
              {breadcrumb}
            </BreadcrumbItem>
          )
        )}
      </Breadcrumb>
    </React.Fragment>
  );
}
