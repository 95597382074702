class DateHelper {
  format = (date) => {
    if (null === date || undefined === date) {
      return null;
    }

    return new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(date));
  };

  formattime = (date) => {
    if (null === date || undefined === date) {
      return null;
    }

    return new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };
  datetime = () => {
    const today = new Date();
    const val =
      today.getFullYear() +
      '_' +
      this.deuxDigit(today.getDate()) +
      '_' +
      this.deuxDigit(today.getMonth() + 1) +
      '_' +
      this.deuxDigit(today.getHours()) +
      this.deuxDigit(today.getMinutes()) +
      this.deuxDigit(today.getSeconds());

    return val;
  };

  deuxDigit = (val) => {
    let retVal = (val + '').split('').reverse().join('');
    return (retVal + '00').substring(0, 2).split('').reverse().join('');
  };

  dateIso = (date) => {
    return date.toISOString().substring(0, 10);
  };
}

export default new DateHelper();
