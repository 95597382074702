import React from 'react';
import PropTypes from 'prop-types';

import { Table } from '@dataesr/react-dsfr';

import LoaderCommissionnement from '../loaders/LoaderCommissionnement';

export default function TableWithLoader({
  data,
  columns,
  isLoading,
  caption,
  rowKey,
  customClassName,
}) {
  const baseClassName = 'fr-app-table no-padding-top no-margin-bottom';
  return (
    <>
      {isLoading ? (
        <LoaderCommissionnement className={'loader-center'} />
      ) : (
        <div className="tableContainerWithLoader">
          <Table
            className={
              customClassName
                ? baseClassName + ' ' + customClassName
                : baseClassName
            }
            caption={caption}
            rowKey={rowKey}
            data={data}
            columns={columns.filter((col) => {
              return false !== col.display;
            })}
          />
        </div>
      )}
    </>
  );
}

TableWithLoader.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  isLoading: PropTypes.bool,
  caption: PropTypes.string,
  rowKey: PropTypes.string,
  customClassName: PropTypes.string,
};
