import { localStorageSearchKey } from '../configs/localStorageSearchKey';

class LocalStorageService {
  cleanSearch() {
    localStorage.removeItem(localStorageSearchKey.searchAgentsListe);
    localStorage.removeItem(localStorageSearchKey.searchArriveesAgentsListe);
    localStorage.removeItem(
      localStorageSearchKey.searchAgentsFicheDePosteListe
    );
    localStorage.removeItem(
      localStorageSearchKey.searchArchivesAgentsRetraitesListe
    );
    localStorage.removeItem(
      localStorageSearchKey.searchArchivesAgentsArchivesListe
    );
    localStorage.removeItem(
      localStorageSearchKey.searchFormationsSessionsListe
    );
    localStorage.removeItem(localStorageSearchKey.searchAgentsHabilitesListe);
    localStorage.removeItem(
      localStorageSearchKey.searchAgentsCommissionnesListe
    );
    localStorage.removeItem(localStorageSearchKey.searchSuiviActiviteListe);
  }
}

export default new LocalStorageService();
