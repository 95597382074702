import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@dataesr/react-dsfr';

export default function TableNbResultat({
  isLoading,
  nbResultat,
  nbResultatAffiche,
  hasSearchedAtLeastOnce,
}) {
  return (
    <>
      {isLoading || !hasSearchedAtLeastOnce ? (
        <></>
      ) : (
        <Text size="xs" className="roboto-italic fr-app-descriptif">
          {nbResultat < 2
            ? nbResultat + ' résultat'
            : nbResultat + ' résultats'}
          {nbResultatAffiche > 0 && nbResultatAffiche < nbResultat
            ? ' (' + nbResultatAffiche + ' affichés)'
            : ''}
        </Text>
      )}
    </>
  );
}

TableNbResultat.propTypes = {
  isLoading: PropTypes.bool,
  nbResultat: PropTypes.number,
  nbResultatAffiche: PropTypes.number,
  hasSearchedAtLeastOnce: PropTypes.bool,
};
