import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Button } from '@dataesr/react-dsfr';
import { suiviActiviteRoutesAPI } from '../../configs/routesAPI';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import SuiviActiviteTable from './SuiviActiviteTable';
import { localStorageSearchKey } from '../../configs/localStorageSearchKey';
import LoaderCommissionnement from '../../components/shared/loaders/LoaderCommissionnement';
import DateHelper from '../../helpers/DateHelper';
import Period from '../../components/shared/selects/Period';
import { ButtonGroup } from '@dataesr/react-dsfr';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';

export default function SuiviActivite() {
  const [actions, setActions] = useState([]);
  const [objetsAction, setObjetsAction] = useState([]);
  const [typesAction, setTypesAction] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitiating, setIsInitiating] = useState(true);
  const [totalActions, setTotalActions] = useState(0);
  const [hasSearchedAtLeastOnce, setHasSearchedAtLeastOnce] = useState(false);

  const { register, getValues, handleSubmit, reset } = useForm();

  useEffect(() => {
    document.title = "Suivi d'activité | Commissionnement";
    axios
      .get(suiviActiviteRoutesAPI.getObjetsAction)
      .then((response) => {
        setObjetsAction(response.data.data);
      })
      .catch((err) => {
        console.log({ err });
      });

    initSearch();
  }, []);

  function initSearch() {
    const params = JSON.parse(
      localStorage.getItem(localStorageSearchKey.searchSuiviActiviteListe)
    );
    if (params !== null) {
      reset({
        objetActionId: params.objet_action_id,
        typeActionId: params.type_action_id,
        dateDebut: params.date_debut,
        dateFin: params.date_fin,
      });
      objectActionChanged();
      search(params);
    } else {
      setIsInitiating(false);
      const today = new Date();
      const twoMonthsAgo = new Date(today.setMonth(today.getMonth() - 2));
      reset({
        dateDebut: DateHelper.dateIso(twoMonthsAgo),
        dateFin: DateHelper.dateIso(new Date()),
      });
    }
  }

  const objectActionChanged = (objectActionId) => {
    axios
      .get(suiviActiviteRoutesAPI.getTypesAction, {
        params: { objet_action_id: objectActionId },
      })
      .then((response) => {
        setTypesAction(response.data.data);
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => {
        setIsInitiating(false);
      });
  };

  const onSubmit = (data, event) => {
    setIsLoading(true);
    event.preventDefault();

    const params = {
      objet_action_id: data.objetActionId,
      type_action_id: data.typeActionId,
      date_debut: data.dateDebut,
      date_fin: data.dateFin,
    };

    search(params);
  };

  const search = (params) => {
    setIsLoading(true);
    localStorage.setItem(
      localStorageSearchKey.searchSuiviActiviteListe,
      JSON.stringify(params)
    );
    axios
      .get(suiviActiviteRoutesAPI.getSuiviActivite, { params: params })
      .then((response) => {
        setActions(response.data.data);
        setTotalActions(response.data.total_actions);
        setHasSearchedAtLeastOnce(true);
      })
      .catch((err) => {
        console.log('error', err);
      })
      .finally(() => {
        setIsLoading(false);
        setHasSearchedAtLeastOnce(true);
      });
  };

  const downloadSuiviActionsCsv = () => {
    const dateDebut = getValues('dateDebut');
    const dateFin = getValues('dateFin');
    const params = {
      objet_action_id: getValues('objetActionId'),
      type_action_id: getValues('typeActionId'),
      date_debut: dateDebut,
      date_fin: dateFin,
    };

    axios({
      url: suiviActiviteRoutesAPI.exportSuiviActivite,
      method: 'GET',
      responseType: 'blob',
      params: params,
    }).then((response) => {
      const blob = new Blob([response.data], {
        type: 'text/csv;',
      });
      saveAs(
        blob,
        `Suivi_d_activité_du_` +
          DateHelper.format(dateDebut) +
          `_au_` +
          DateHelper.format(dateFin) +
          `.csv`
      );
    });
  };

  return isInitiating ? (
    <LoaderCommissionnement />
  ) : (
    <div>
      <section className="fr-app-section fr-app-bloc-filtre agent-search-filtre">
        <form className="fr-app-form" onSubmit={handleSubmit(onSubmit)}>
          <Container fluid>
            <Row>
              <Col n="6">
                <label
                  className="fr-label"
                  htmlFor="objetActionId"
                  aria-describedby=""
                >
                  Objet d'action :
                </label>
                <select
                  className="fr-select"
                  name="objetActionId"
                  id="objetActionId"
                  {...register('objetActionId')}
                  onChange={(e) => objectActionChanged(e.target.value)}
                >
                  <option key="" value="">
                    Tous
                  </option>
                  {objetsAction.map((objetAction) => {
                    return (
                      <option key={objetAction.id} value={objetAction.id}>
                        {objetAction.label}
                      </option>
                    );
                  })}
                </select>
              </Col>

              <Col n="6">
                <label
                  className="fr-label"
                  htmlFor="typeActionId"
                  aria-describedby=""
                >
                  Type d'action :
                </label>
                <select
                  className="fr-select"
                  name="typeActionId"
                  id="typeActionId"
                  {...register('typeActionId')}
                >
                  <option key="" value="">
                    Tous
                  </option>
                  {typesAction.map((typeAction) => {
                    return (
                      <option key={typeAction.id} value={typeAction.id}>
                        {typeAction.label}
                      </option>
                    );
                  })}
                </select>
              </Col>

              <Period register={register}></Period>

              <Col n="12">
                <Button submit>Rechercher</Button>
              </Col>
            </Row>
          </Container>
        </form>
      </section>
      <section className="fr-app-section">
        <SuiviActiviteTable
          actions={actions}
          isLoading={isLoading}
          totalActions={totalActions}
          hasSearchedAtLeastOnce={hasSearchedAtLeastOnce}
        />
        <ButtonGroup isEquisized align="center" isInlineFrom="md">
          <Link
            type="button"
            className="fr-btn--md fr-btn"
            title="Export CSV"
            onClick={downloadSuiviActionsCsv}
            to={''}
            download
          >
            Export CSV
          </Link>
        </ButtonGroup>
      </section>
    </div>
  );
}
