import { roleEnum } from '../enums/RoleEnum';
import { typeCommissionnementEnum } from '../enums/TypeCommissionnementEnum';
import UserService from './user.service';

class UserProfileService {
  //AG1
  isAdminGenICPE() {
    return (
      UserService.hasEffectiveRole(roleEnum.ADMINISTRATEUR_GENERAL) &&
      UserService.isIcpe()
    );
  }

  //AG2
  isAdminGenEauNature() {
    return (
      UserService.hasEffectiveRole(roleEnum.ADMINISTRATEUR_GENERAL) &&
      UserService.isEauNature()
    );
  }

  //A1
  isAdminICPE() {
    return (
      UserService.hasEffectiveRole(roleEnum.ADMINISTRATEUR) &&
      UserService.isIcpe()
    );
  }

  //A2
  isAdminDGPR() {
    return (
      UserService.hasEffectiveRole(roleEnum.ADMINISTRATEUR) &&
      UserService.isEauNature() &&
      UserService.hasEffectiveTypeCommissionnement(
        typeCommissionnementEnum.DGPR
      )
    );
  }

  //A3
  isAdminDGALN() {
    return (
      UserService.hasEffectiveRole(roleEnum.ADMINISTRATEUR) &&
      UserService.isEauNature() &&
      UserService.hasEffectiveTypeCommissionnement(
        typeCommissionnementEnum.DGALN
      ) &&
      UserService.hasEffectiveTypeCommissionnement(
        typeCommissionnementEnum.CT
      ) &&
      UserService.hasEffectiveTypeCommissionnement(
        typeCommissionnementEnum.RESERVES
      ) &&
      UserService.hasEffectiveTypeCommissionnement(
        typeCommissionnementEnum.LITTORAL
      )
    );
  }
}

export default new UserProfileService();
