import React from 'react';
import PropTypes from 'prop-types';
import { Col, TextInput } from '@dataesr/react-dsfr';
import FormHelper from '../../../helpers/FormHelper';
import ReactTooltip from 'react-tooltip';

export default function Period({
  register,
  labelDebut,
  labelFin,
  errors,
  minDate,
  maxDate,
  withTooltip,
}) {
  const today = new Date();

  return (
    <>
      <Col n="6">
        {withTooltip ? (
          <div
            style={{
              position: 'relative',
            }}
          >
            <TextInput
              className="required-field fr-input-wrap fr-fi-calendar-line"
              label={labelDebut ?? 'Début'}
              type="date"
              {...register('dateDebut', {
                min: minDate,
                max: maxDate,
              })}
              min={minDate ?? '2010-01-01'}
              max={maxDate ?? today}
            />
            <span
              data-for="date-min-condition"
              data-tip={'Impossible de saisir une date < à ' + minDate}
              data-event="click focus"
              data-iscapture="false"
              className="ri-information-line fr-app-tooltip-info-color"
              role="img"
              aria-label="En savoir plus"
              style={{
                top: '0',
                left: '7.5rem',
                fontSize: '1.2rem',
                position: 'absolute',
              }}
            />
            <ReactTooltip id="date-min-condition" multiline={true} />
          </div>
        ) : (
          <TextInput
            className="required-field fr-input-wrap fr-fi-calendar-line"
            label={labelDebut ?? 'Début'}
            type="date"
            {...register('dateDebut', {
              min: minDate,
              max: maxDate,
            })}
            min={minDate ?? '2010-01-01'}
            max={maxDate ?? today}
          />
        )}
      </Col>
      {FormHelper.displayErrHtml(errors?.dateDebut)}

      <Col n="6">
        {withTooltip ? (
          <div
            style={{
              position: 'relative',
            }}
          >
            <TextInput
              className="required-field fr-input-wrap fr-fi-calendar-line"
              label={labelFin ?? 'Fin'}
              type="date"
              {...register('dateFin', {
                min: minDate,
                max: maxDate,
              })}
              min={minDate ?? '2010-01-01'}
              max={maxDate ?? today}
            />
            <span
              data-for="date-max-condition"
              data-tip={'Impossible de saisir une date > à ' + maxDate}
              data-event="click focus"
              data-iscapture="false"
              className="ri-information-line fr-app-tooltip-info-color"
              role="img"
              aria-label="En savoir plus"
              style={{
                top: '0',
                left: '7.7rem',
                fontSize: '1.2rem',
                position: 'absolute',
              }}
            />
            <ReactTooltip id="date-max-condition" multiline={true} />
          </div>
        ) : (
          <TextInput
            className="required-field fr-input-wrap fr-fi-calendar-line"
            label={labelFin ?? 'Fin'}
            type="date"
            {...register('dateFin', {
              min: minDate,
              max: maxDate,
            })}
            min={minDate ?? '2010-01-01'}
            max={maxDate ?? today}
          />
        )}
      </Col>
      {FormHelper.displayErrHtml(errors?.dateFin)}
    </>
  );
}

Period.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  labelDebut: PropTypes.string,
  labelFin: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  withTooltip: PropTypes.bool,
};
