import UserProfileService from './userprofile.service';

class StatsAccessService {
  canAccessAgentsCommissionnes() {
    return (
      UserProfileService.isAdminGenICPE() ||
      UserProfileService.isAdminICPE() ||
      UserProfileService.isAdminDGPR() ||
      UserProfileService.isAdminDGALN()
    );
  }

  canAccessAgentsCommissionnesAnnee() {
    return (
      UserProfileService.isAdminGenICPE() ||
      UserProfileService.isAdminICPE() ||
      UserProfileService.isAdminDGPR() ||
      UserProfileService.isAdminDGALN()
    );
  }

  canAccessAgentsCommissionnesStatut() {
    return (
      UserProfileService.isAdminGenICPE() ||
      UserProfileService.isAdminICPE() ||
      UserProfileService.isAdminDGPR()
    );
  }

  canAccessAgentsDecommissionnesAnnee() {
    return (
      UserProfileService.isAdminGenICPE() ||
      UserProfileService.isAdminICPE() ||
      UserProfileService.isAdminDGPR() ||
      UserProfileService.isAdminDGALN()
    );
  }

  canAccessAgentsZoneChangeeAnnee() {
    return (
      UserProfileService.isAdminGenICPE() ||
      UserProfileService.isAdminICPE() ||
      UserProfileService.isAdminDGPR() ||
      UserProfileService.isAdminDGALN()
    );
  }

  canAccessAgentsChangementCommissionnementAnnee() {
    return UserProfileService.isAdminDGALN();
  }

  canAccessAgentsCommissionnesHabilitation() {
    return UserProfileService.isAdminDGALN();
  }

  canAccessAgentsSuiviFormationsAnnee() {
    return (
      UserProfileService.isAdminGenICPE() || UserProfileService.isAdminICPE()
    );
  }

  canAccessAgentsTBSuiviPDP() {
    return (
      UserProfileService.isAdminGenICPE() || UserProfileService.isAdminICPE()
    );
  }

  canAccessAgentsSuiviRepartitionPDP() {
    return (
      UserProfileService.isAdminGenICPE() || UserProfileService.isAdminICPE()
    );
  }

  canAccessAgentsNonInscritsAnnee() {
    return (
      UserProfileService.isAdminGenICPE() || UserProfileService.isAdminICPE()
    );
  }
}

export default new StatsAccessService();
