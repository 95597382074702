import axios from 'axios';
import eventBus from '../eventBus';
import { agentRoutes, aTraiterRoutesBaseName } from '../configs/routes';
import { roleEnum } from '../enums/RoleEnum';
import { typeCommissionnementEnum } from '../enums/TypeCommissionnementEnum';

export const ICPE = 'ICPE';
export const EAU_ET_NATURE = 'EAU_ET_NATURE';

class UserService {
  async fetchUser() {
    await axios.get('/user').then((response) => {
      if (response && response.data) {
        this.setUser(response.data.data);
      }
    });
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
    eventBus.dispatch('userSet', user);
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  canSwitchProfil() {
    return this.getCurrentUser()?.can_switch_profil;
  }

  hasSwitchedProfil() {
    return this.getCurrentUser()?.has_switched_profil;
  }

  applicationContext() {
    return this.getCurrentUser()?.effective_profil?.application_context;
  }

  hasEffectiveRoleAmong(roles) {
    return roles.includes(this.getCurrentUser().effective_profil?.role);
  }

  hasEffectiveTypeCommissionnement(tc) {
    return this.getCurrentUser().effective_profil?.types_commissionnement?.includes(
      tc
    );
  }

  hasEffectiveRole(role) {
    return this.hasEffectiveRoleAmong([role]);
  }

  isGestionnaire() {
    return this.hasEffectiveRole(roleEnum.GESTIONNAIRE);
  }

  isGestionnaireFrance() {
    return this.isGestionnaire() && this.sousOrganisme() === null;
  }

  isConsultant() {
    return this.hasEffectiveRole(roleEnum.CONSULTANT);
  }

  isTypeCommissionnementDGPR() {
    return (
      this.hasEffectiveTypeCommissionnement(typeCommissionnementEnum.IIC) ||
      this.hasEffectiveTypeCommissionnement(typeCommissionnementEnum.DGPR)
    );
  }

  isTypeCommissionnementDGALN() {
    return (
      this.hasEffectiveTypeCommissionnement(typeCommissionnementEnum.DGALN) ||
      this.hasEffectiveTypeCommissionnement(typeCommissionnementEnum.CT) ||
      this.hasEffectiveTypeCommissionnement(
        typeCommissionnementEnum.RESERVES
      ) ||
      this.hasEffectiveTypeCommissionnement(typeCommissionnementEnum.LITTORAL)
    );
  }

  isAdministrateur() {
    return this.hasEffectiveRoleAmong([
      roleEnum.ADMINISTRATEUR,
      roleEnum.ADMINISTRATEUR_GENERAL,
    ]);
  }

  sousOrganisme() {
    return this.getCurrentUser()?.effective_profil?.sous_organisme;
  }

  isIcpe() {
    return ICPE === this.applicationContext();
  }

  isEauNature() {
    return EAU_ET_NATURE === this.applicationContext();
  }

  getDefaultRoute() {
    return this.isConsultant() ? agentRoutes.list : aTraiterRoutesBaseName;
  }
}

export default new UserService();
